import './content.css'
import Contentheader from './Contentheader';
import exhibition1 from '../images/event_operator/eo1.png';
import exhibiton2 from '../images/event_operator/eo2.png';
import exhibiton3 from '../images/event_operator/eo3.png';
import exhibiton4 from '../images/event_operator/eo4.png';

import { useState } from "react";
import Modal from './Modal';


function Exhibition(){

   const exhibitionhandler=()=>{
      const element = document.getElementById("exhibition");
      element.scrollIntoView();
   }
   const Customize=()=>{
    const element = document.getElementById("Customize");
    element.scrollIntoView();
 }
 const Update=()=>{
    const element = document.getElementById("Update");
    element.scrollIntoView();
 }

 const Enable=()=>{
    const element = document.getElementById("Enable");
    element.scrollIntoView();
 }


 const[popup,setpopup]= useState(false);



return(<div>{popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Event Operator Management </h2>
 <h4 className='stepcontent'>Learn how to configure Event Operator</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={exhibitionhandler}>Event Operator Management</div> 
  <div className='contentcategories' onClick={Customize} >Customize items</div>
  <div  onClick={Update} className='contentcategories'>Selecting the event to Configure</div>
  <div  onClick={Enable} className='contentcategories'>Enable and Disable Configurations</div>
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Event Operator Management</h3>
<p className='lines' id='exhibition'>
Event Operator page: Subscribers can create operators of different role.The event operator once created,are assigned various events created by their subscriber.And as the operator logs in he is further alloted only few module access.An event operator cannot access all features of subscription module.It is only through permissions that he will have access to different features .
</p>

<p className='lines' id='register'>

An event operator can have multiple event operators and  based on that he can customise the module features.An event operator will not have access to add-ons or purchase of any items.He however can register forms,create events  and access other multiple modules.
</p>

<div id='Update' className='pb-5 pt-3'><img src={exhibition1} height="400px" width='80%'></img></div><br></br>
<div className='pb-5 pt-3'><img src={exhibiton2} height="400px" width='80%'></img></div><br></br>
<div id='Enable' className='pb-5 pt-3'><img src={exhibiton3} height="400px" width='80%'></img></div>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

</div>
</div>



   </div>

</div>
</div>)



}

export default Exhibition;