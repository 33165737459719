import './content.css'
import Contentheader from './Contentheader';
import booth from '../images/booth_menu.png';
import booth1 from '../images/booth1.png';
import booth2 from '../images/booth2.png';
import boothconfig from '../images/boothconfig.png';
import booth4 from '../images/booth_banners.png';
import booth5 from '../images/booth_social.png';
import booth6 from '../images/booth_video.png';
import booth7 from '../images/boot_pdf.png';
import { useState } from "react";
import Modal from './Modal';

function Booth(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Banners=()=>{
      const element = document.getElementById("banners");
      element.scrollIntoView();
   }
   const Social=()=>{
      const element = document.getElementById("social");
      element.scrollIntoView();
   }
   const Video=()=>{
      const element = document.getElementById("video");
      element.scrollIntoView();
   }
   const Pdf=()=>{
      const element = document.getElementById("pdf");
      element.scrollIntoView();
   }
   const Ref=()=>{
      const element = document.getElementById("ref");
      element.scrollIntoView();
   }



   const[popup,setpopup]= useState(false);




return(<div>
     {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MAv5iOP1f5k'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Booth Management </h2>
 <h4 className='stepcontent'>Learn how to showcase your Booth</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Booth Management</div> 
  <div className='contentcategories' onClick={Banners}>Banners</div> 
  <div className='contentcategories' onClick={Pdf}>Pdf Rack</div> 
  <div className='contentcategories' onClick={Video}>Video Gallery</div> 
  <div className='contentcategories' onClick={Social}>Social Media</div> 
  <div className='contentcategories' onClick={Ref}>Reference View</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Booth Management</h3>
<p className='lines' id='Featuress' >

The purpose of  Booth in a virtual Event is to provide space to exhibitors to display their products by allowing upload of videos , images ,pdfs or downloadable units in an online space.

<pre></pre>
This module helps subscriber showcase his booth with ease.

</p>

<div><img src={booth} height="50%" width='50%'></img></div>


<pre></pre>

<li className='lines_' id='Customize'>We can configure the following features of booth:
<pre></pre>
<ul className='lines'>
   


<li className='lines' id='banners'>Banners:<br></br>
Type of banner<br></br>
Banner position<br></br>
Thumbnail for the banner <br></br>
Banner content
 </li> <br></br>
 <div><img src={booth4} height="100%" width='100%'></img></div>
 <br></br>
<li className='lines' id='pdf'> Pdf Rack<br></br>
Upload multiple pdfs with proper title
 </li> <br></br>
 <div><img src={booth7} height="100%" width='100%'></img></div>
 <br></br>
<li className='lines' id='video'>Video Gallery<br></br>
Upload Videos or embed youtube  links .
 </li> <br></br>
 <div ><img src={booth6} height="100%" width='100%'></img></div>
 <br></br>
<li className='lines' id='social'>
Social Media<br></br>

Users can add social media icons and the associated link for it.

     </li><br></br>
     <div><img src={booth5} height="100%" width='100%'></img></div><br></br>
<li className='lines_'>Customize the booth elements:

<ul className='lines'>
<li className='lines'>Enter the Link Name to show on booth front end</li>
<li className='lines'>Keeping the dimensions of template intact users can upload updated booth Image
</li>
<li className='lines'>MAX 4MB Booth Thumbnail in Hall</li>
<li className='lines'>Option to remove the thumbnail</li>
<li className='lines'>Users can add or remove the Main TV for Booth</li>
<li className='lines'>Logos can be added or removed </li>
<li className='lines'>Url can updated for the logo</li>
<li className='lines'>Users can add Javascript and showcase chat option</li>
<li className='lines'>They can also add Video Chat like zoom,meet etc with appropriate urls</li>
<li className='lines'>Calenders can be displayed on the booth</li>
<li className='lines'>Attendees can exchange Business Cards between them</li>

</ul>
 </li>
 <br></br>
 <div id='ref'><img src={boothconfig} height="100%" width='100%'></img></div>
 <br></br>

</ul></li>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>



</div>
</div>



   </div>

</div>
</div>)



}

export default Booth;