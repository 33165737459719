import './content.css'
import Contentheader from './Contentheader';
import { useState } from "react";
import order from '../images/weborders/orders.png';
import invoice from '../images/weborders/invoice_ord.png';
import Modal from './Modal';

function Orders(){

  const[popup,setpopup]= useState(false);
   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Preview=()=>{
    const element = document.getElementById("landing1");
    element.scrollIntoView();
 }
 const Editlink=()=>{
    const element = document.getElementById("landing2");
    element.scrollIntoView();
 }

 const Disclaimer=()=>{
    const element = document.getElementById("landing4");
    element.scrollIntoView();
 }






return(<div>

{popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=Bzlsfu3VfY8'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Orders</h2>
 <h4 className='stepcontent'>Learn how to manage Orders</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Orders</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Orders</h3>
<p className='lines' >

Orders :This page deals with list of subscribers who have placed an order for  add ons and carried out the transaction using payment gateway.
It will display the list of subscribers ,transaction payment details and date of transaction.

</p>
<br></br>
<div id='orders'>
<div id='Update' className='pb-5 '><img src={order} height="100%" width='100%'></img></div></div>
<br></br>
<p className='lines'>
We can even get the detailed invoice form of it on click of details on te side button.
</p>
<div id='orders'>
<div id='Update' className='pb-5 '><img src={invoice} height="100%" width='100%'></img></div></div>
<div id='Update' className='pb-5 '></div>
<p className='lines' id='subscription'>


</p>
<br></br>


   
       
    
  
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>
<br></br><br></br><br></br>

</div>
</div>



   </div>

</div><br></br><br></br>
</div>)



}

export default Orders;