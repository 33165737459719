import './request.css';
import logo from './images/logo.png';
import { Link } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import requestimage from './requestimages/image 9.png';
import registered from './requestimages/registered 1.png'
function Request(){

return(

<div className=' '>
<div class="navbar  ">
    <div className='topleft'>
   <div className='ms-3'><img   src={logo} height="100%" width="70%"></img>   </div>

   <div  ><Tippy className='tippy' content=' Go To Home'><Link to='/home'  className='home '  ><i class="bi bi-house-fill homebtn"></i> </Link></Tippy></div>
    </div>
    
  

    <div className=' '>
    <Link to='/calculator'  className="topbuttons  " ><i class="bi topicons bi-calculator-fill"></i> Calculator</Link>
 
    <a href='https://subscription.bizconnect.events/signup'  id='subscribe' className=" topbuttons  " >Subscribe</a>
  
    </div>
    
  </div>



 
 <div className=' pricing'><pre></pre><pre></pre>
<h1   className="   text-white submit-request  text-center  " >

      SUBMIT REQUEST 
     
     </h1><pre></pre><pre></pre>
     </div>
<div className='conat'>
     <div className="row">
     
    
       <div className="col-md-7">
       <form className="form" id="form">
        <h3 id='sub' className=" border-bottom pb-2 fonts formcontentbackgroundcolor  "   >Registration Form</h3>
        <div className="row">
          <div className="form-group col-md-6">
            <label className='fonts formcontent' for="firstName">First Name</label>
            <input type="text" className="form-control form-control2" id="firstName" placeholder="" />
          </div>
          <div className="form-group col-md-6">
            <label className='fonts formcontent' for="lastName">Last Name</label>
            <input type="text" className="form-control form-control2" id="lastName" placeholder="" />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className='fonts formcontent' for="Email">Email ID</label>
            <input type="email" className="form-control form-control2" id="Email" placeholder=""/>
          </div>
          <div className="form-group col-md-6">
            <label className='fonts formcontent' for="phoneNumber">Phone Number</label>
            <input type="tel" className="form-control form-control2" id="phoneNumber" placeholder="" />
          </div>
        </div>

        <div className='row'>
        <div className="form-group col-md-12">
          <label className='fonts formcontent' for="queryType">Query Type </label>
          <input type="text" className="form-control form-control2" id="queryType" placeholder=" " />
        </div>
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <label className='fonts formcontent' for="inputCity">City</label>
            <input type="text" className="form-control form-control2" id="inputCity" />
          </div>
          <div className="form-group col-md-4 ">
            <label for="inputState fonts formcontentps-2" className='formcontent fonts'>State</label>
            <select name="inputState" id="inputState" className="form-control form-control2">
             <option value="Andhra Pradesh">Andhra Pradesh</option>
             <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
             <option value="Arunachal Pradesh">Arunachal Pradesh</option>
             <option value="Assam">Assam</option>
             <option value="Bihar">Bihar</option>
             <option value="Chandigarh">Chandigarh</option>
             <option value="Chhattisgarh">Chhattisgarh</option>
             <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
             <option value="Daman and Diu">Daman and Diu</option>
             <option value="Delhi">Delhi</option>
             <option value="Lakshadweep">Lakshadweep</option>
             <option value="Puducherry">Puducherry</option>
             <option value="Goa">Goa</option>
             <option value="Gujarat">Gujarat</option>
             <option value="Haryana">Haryana</option>
             <option value="Himachal Pradesh">Himachal Pradesh</option>
             <option value="Jammu and Kashmir">Jammu and Kashmir</option>
             <option value="Jharkhand">Jharkhand</option>
             <option value="Karnataka">Karnataka</option>
             <option value="Kerala">Kerala</option>
             <option value="Madhya Pradesh">Madhya Pradesh</option>
             <option value="Maharashtra">Maharashtra</option>
             <option value="Manipur">Manipur</option>
             <option value="Meghalaya">Meghalaya</option>
             <option value="Mizoram">Mizoram</option>
             <option value="Nagaland">Nagaland</option>
             <option value="Odisha">Odisha</option>
             <option value="Punjab">Punjab</option>
             <option value="Rajasthan">Rajasthan</option>
             <option value="Sikkim">Sikkim</option>
             <option value="Tamil Nadu">Tamil Nadu</option>
             <option value="Telangana">Telangana</option>
             <option value="Tripura">Tripura</option>
             <option value="Uttar Pradesh">Uttar Pradesh</option>
             <option value="Uttarakhand">Uttarakhand</option>
             <option value="West Bengal">West Bengal</option>
             </select>
          </div>
          <div className="form-group col-md-4">
            <label className='fonts formcontent' for="inputZip">Zip</label>
            <input type="text" className="form-control form-control2" id="inputZip" />
          </div>
        </div>
        <div className="form-group col-md-12">
         <label className='fonts formcontent' for="description">Description</label>
         <textarea className="form-control" rows="11" id="description"></textarea>
       </div>
        <div className="form-group d-flex justify-content-between align-items-center ">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" required id="gridCheck" />
            <label className='fonts formcontent form-check-label '  for="gridCheck">
              <span className='ms-2 mt-2'> 
          I Agree to terms and conditions</span>
            </label>
          </div>
          <button type="submit" className="btn topbuttons mt-2">Submit</button>
        </div>
      </form>
     </div>
    
      <div className='col-md-5 ' >
       <h2 className="text-center fonts ">Register Here</h2>
       <div className="d-flex justify-content-around align-items-center  " >
        <a href="#form">
         <img src={registered}  className="" width="70px" alt="" />
        </a>
       </div>
       <div><img src={requestimage}  className="img-fluid img-1" draggable="false" alt="" /></div>
      </div></div></div></div>

);


}

export default Request;