import './content.css'
import Contentheader from './Contentheader';
import landing1 from '../images/landing1.png';
import landing2 from '../images/landing2.png';
import landing3 from '../images/landing3.png';
import landing4 from '../images/landing4.png';
import landing5 from '../images/landing5.png';
import { useState } from "react";
import Modal from './Modal';

function Reports(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Preview=()=>{
    const element = document.getElementById("landing1");
    element.scrollIntoView();
 }
 const Editlink=()=>{
    const element = document.getElementById("landing2");
    element.scrollIntoView();
 }

 const Disclaimer=()=>{
    const element = document.getElementById("landing4");
    element.scrollIntoView();
 }

 const[popup,setpopup]= useState(false);




return(<div>
      {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MiITmJA8yd0'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Reports </h2>
 <h4 className='stepcontent'>Learn how to manage Reports</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Reports</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Reports</h3>
<p className='lines' >


A subscriber can get the detailed report of the pages visited by any visitor to the site.Inorder for that we use either google analytics or detailed report through session captures.<br></br>
There are 2 basic sections :<br></br>
Google analytics :The subscriber will have to choose the event he wants after which he will submit the tracker id to view the analytic report .


<br></br>

Basic log report :This module will display the activity log report of users entering the event front end.The subscriber can view<pre></pre>
-Name of the user<br></br>
-Email<br></br>
- Category of the Page <br></br>
-Name of the page<br></br>
-Entry time into the page<pre></pre>



</p>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

<br></br>
<br></br>
<br></br>
<br></br>

</div>
</div>



   </div>

</div>
</div>)



}

export default Reports;