import './content.css'
import Contentheader from './Contentheader';
import { useState } from "react";
import subs from '../images/orders/subscriptions.png';
import Modal from './Modal';

function Orders(){

  const[popup,setpopup]= useState(false);
   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Preview=()=>{
    const element = document.getElementById("landing1");
    element.scrollIntoView();
 }
 const Editlink=()=>{
    const element = document.getElementById("landing2");
    element.scrollIntoView();
 }

 const Disclaimer=()=>{
    const element = document.getElementById("landing4");
    element.scrollIntoView();
 }






return(<div>

{popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=Bzlsfu3VfY8'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Orders</h2>
 <h4 className='stepcontent'>Learn how to manage Orders</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Orders</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Orders</h3>
<p className='lines' >

Orders :This page deals with the list of subscriptions processed and the transactions done through payment gateway.
It will display the list of subscribers ,transaction payment details and date of transaction.

</p>
<br></br>
<div className='table-responsive-lg' id='orders'>
<table class="table table-responsive  ">
  <thead  className="tableheading">
    <tr>
      <th  className="tableheader" scope="col col-md-1">Id</th>
      <th className="tableheader" scope="col col-md-2">Subscriber</th>
      <th className="tableheader col-3 text-start" scope="col col-md-2 ">Transaction Id</th>
      <th className="tableheader" scope="col col-md-2 ">Date</th>
      <th className="tableheader" scope="col col-md-1 ">Sub Total</th>
      <th className="tableheader" scope="col col-md-1 ">Total</th>
      <th className="tableheader" scope="col col-md-1 ">Action</th>
    </tr>
  </thead>
  <tbody>
    
           <tr >
      <th className="tablecontent" scope="row">2</th>
      <td className="tablecontent ">Rashmi K</td>
      <td className="tablecontent text-start"></td>
      <td className="tablecontent">05-01-2022 11:39:01am</td>
      <td  className="tablecontent"> $340</td>
      <td  className="tablecontent"> $340</td>
      <td  className="tablecontent"> </td>
    </tr>
       
    
  </tbody>
</table></div>
<br></br>
<p className='lines'>
We can even get the detailed invoice form of it on click of details on te side button.
</p>

<div id='Update' className='pb-5 '></div>
<p className='lines' id='subscription'>
Subscriptions: Lists out all the upgraded subscriptions of a user and the payment detai;ls along with the date and the type of subscription Plan.
Subscribers List

</p>
<br></br>
<div className='table-responsive-lg' >
<table class="table table-responsive ">
  <thead  className="tableheading">
    <tr>
      <th  className="tableheader" scope="col col-md-1">Id</th>
      <th className="tableheader" scope="col col-md-2">Subscriber</th>
      <th className="tableheader col-3 text-start" scope="col col-md-2 ">Transaction Id</th>
     

      <th className="tableheader" scope="col col-md-1 ">Total</th>
      <th className="tableheader" scope="col col-md-2 ">Date</th>
      <th className="tableheader" scope="col col-md-1 ">Plan</th>
    </tr>
  </thead>
  <tbody>
    
           <tr >
      <th className="tablecontent" scope="row">22</th>
      <td className="tablecontent ">Rashmi K</td>
      <td className="tablecontent text-start"></td>
      <td className="tablecontent">$1000</td>
      <td  className="tablecontent"> 19-02-2022 04:55:39pm</td>
      <td  className="tablecontent"> 
Premium (Monthly)
</td>
     
    </tr>

    <tr >
      <th className="tablecontent" scope="row">22</th>
      <td className="tablecontent ">Rashmi K</td>
      <td className="tablecontent text-start"></td>
      <td className="tablecontent">$500</td>
      <td  className="tablecontent">
05-01-2022 11:37:02am

</td>
      <td  className="tablecontent"> 
Premium (Monthly)
</td>
     
    </tr>

    <div><img src={subs} height="100%" width='100%'></img></div>
       
    
  </tbody>
</table>
</div>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>
<br></br><br></br><br></br>

</div>
</div>



   </div>

</div><br></br><br></br>
</div>)



}

export default Orders;