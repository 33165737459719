import './content.css'
import Contentheader from './Contentheader';

import event1 from '../images/webinar/new_events/e1.png';
import event2 from '../images/webinar/new_events/e2.png';
 
import { useState } from "react";
import Modal from './Modal';

function Event(){

   const registhandler=()=>{
      const element = document.getElementById("register");
      element.scrollIntoView();
   }
   const viewevents=()=>{
    const element = document.getElementById("view");
    element.scrollIntoView();
 }
 const createevents=()=>{
    const element = document.getElementById("create");
    element.scrollIntoView();
 }

 const editevents=()=>{
    const element = document.getElementById("edit");
    element.scrollIntoView();
 }

 const[popup,setpopup]= useState(false);




return(<div>
   {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
  
    <h2 id='headingcontent'>Completed Events Module </h2>
 

</div>


<pre></pre>
  </div>

</div>
<pre></pre><pre></pre><pre></pre>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={registhandler}>Events Registration</div> 
  <div className='contentcategories' onClick={viewevents} >View Events</div>
  <div  onClick={editevents} className='contentcategories'> Delete Events</div>
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Events Module</h3>
<p className='lines' id='register'>
A subscriber takes a subscription for a period of time in which he can host multiple events.
Completed events are events wich have already been done and available only to view or delete.




</p>
<div><img src={event1} height="130px" width='50%'></img></div><pre></pre>
<p  className='lines' id='view' >
This displays all the events created by the subscriber which are over.
</p>
<pre></pre>
Subscribers can only view and not edit the records.
<div><img src={event2} height="120%" width='100%'></img></div>
<pre></pre>

<pre></pre>
Subscribers can delete a completed event .

<pre></pre>

<p className='lines'  id='edit' >
Once event is completed , there are options to view or delete the events. The subscriber can also view individual events or all the events created by the person.
Click on view button to display the details of individual events
</p>


<pre></pre>
<p className='lines' >
The edit button can be used to change and update event details  .

Events can also be deleted as follows :
</p>

{/* <div><img src={event5} height="100%" width='100%'></img></div>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div> */}

</div>
</div>



   </div>

</div>
</div>)



}

export default Event;