import "./content.css";
import Contentheader from "./Contentheader";
import { useEffect, useState } from "react";
import Modal from "./Modal";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

function Dashboard() {
  let { apiId } = useParams();
  const [blogPost, setBlogPost] = useState({});
  const [showUseFullVideos, setShowUseFullVideos] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);

  useEffect(() => {
    fetch(`https://admin.knowledgebase.bizconnect.events/api/topics/${apiId}`)
      .then((response) => response.json())
      .then((data) => {
        setBlogPost(data);
      });
  }, [apiId]);

  const scrollIntoViewHandler = (ele) => {
    const element = document.getElementById(ele);
    element.scrollIntoView();
  };

  const [popup, setpopup] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  return (
    <div>
      {popup && <Modal setpopup={setpopup} myurl={currentVideo} />}
      <Contentheader />
      <div className="row main ">
        <div className="">
          <div className="searchbox">
            <h2 id="headingcontent">Getting Started with {blogPost.title}</h2>
          </div>

          <pre></pre>
        </div>
      </div>

      <pre></pre>
      <pre></pre>
      <pre></pre>

      {/* dynamic */}

      <div className="row  gx-2 mx-2 ">
        <div className="col">
          <div className="sticky-top mx-3 " style={{ zIndex: 0 }}>
            <div className=" categoriescont p-3  ">
              <div className="categories">Table of Contents</div>
              {blogPost.contents?.map(({ title, type }) => {
                if (type === "video") {
                  return null;
                }
                return (
                  <div
                    onClick={() => {
                      scrollIntoViewHandler(title);
                    }}
                    className="contentcategories"
                  >
                    {title}
                  </div>
                );
              })}
            </div>
            <div
              className={`categoriescont p-3 my-2 ${
                showUseFullVideos ? "" : "d-none"
              } `}
            >
              <div className="categories">Usefull video</div>
              {blogPost.contents?.map(({ type, video, title }) => {
                const WatchVideo = () => {
                  if (type === "video") {
                    setShowUseFullVideos(true);
                    return (
                      <div
                        onClick={() => {
                          setpopup(true);
                          setCurrentVideo(video);
                        }}
                        className="contentcategories"
                      >
                        {title}
                      </div>
                    );
                  } else {
                    return null;
                  }
                };

                return <WatchVideo />;
              })}
            </div>
            <div
              className={`categoriescont p-3 my-2 ${
                showAnnouncement ? "" : "d-none"
              } `}
            >
              <div className="categories">News letter</div>
              {blogPost.announcements?.map(({ topic_id, url, title }) => {
                const WatchVideo = () => {
                  if (topic_id === apiId) {
                    setShowAnnouncement(true);

                    return (
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="contentcategories d-block"
                      >
                        {title}
                      </a>
                    );
                  } else {
                    //   setShowUseFullVideos(false);
                    return null;
                  }
                };

                return <WatchVideo />;
              })}
            </div>
          </div>
        </div>
        <div className="col-9 border py-2 px-lg-4 contentBorder ">
          {blogPost.contents?.map(({ title, type, image, text, video }) => {
            const TextEditor = () => {
              if (!(type === "text_editor")) {
                return null;
              }

              return <div id={title}> {parse(text)} </div>;
            };
            const Image = () => {
              if (!(type === "image")) {
                return null;
              }

              return (
                <img
                  id={title}
                  className="img-fluid my-3"
                  src={"https://admin.knowledgebase.bizconnect.events/" + image}
                  alt=""
                />
              );
            };

            return (
              <>
                <TextEditor />
                <Image />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
