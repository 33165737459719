import './content.css'
import Contentheader from './Contentheader';

import n1 from '../images/navbar/n1.png';

import n2 from '../images/navbar/n2.png';
import n3 from '../images/navbar/n3.png';

import { useState } from "react";
import Modal from './Modal';

function Event(){

   const registhandler=()=>{
      const element = document.getElementById("register");
      element.scrollIntoView();
   }
   const viewevents=()=>{
    const element = document.getElementById("view");
    element.scrollIntoView();
 }
 const createevents=()=>{
    const element = document.getElementById("create");
    element.scrollIntoView();
 }

 const editevents=()=>{
    const element = document.getElementById("edit");
    element.scrollIntoView();
 }

 const[popup,setpopup]= useState(false);




return(<div>
   {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
  
    <h2 id='headingcontent'>Events Module </h2>
 <h4 className='stepcontent'>Learn how to Register,Edit,Delete your Event</h4>

</div>


<pre></pre>
  </div>

</div>
<pre></pre><pre></pre><pre></pre>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={registhandler}>Navigation</div> 
  <div className='contentcategories' onClick={viewevents} >View Navigation</div>
  <div  onClick={createevents} className='contentcategories'>Edit</div>
  
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Navigation Module</h3>
<p className='lines' id='register'>
An organized list of links to other web pages, usually internal pages. Navigation menus appear most commonly in page headers or sidebars across a website, 
allowing visitors to quickly access the most useful pages.Subscription navigation module enables subscribers to create attractive navigationas per the template style chosen.
Navigation could be straight or even circular.Subscribers can customise it as per their needs.


</p>
<div><img src={n1} height="200px" width='50%'></img></div><pre></pre>
<p  className='lines' id='view' >
This page displays the menubar created by the subscriber .
</p>
<pre></pre>
<div><img src={n2} height="120%" width='100%'></img></div>
<pre></pre>

<pre></pre>
<p className='lines' id='create' >
Subscribers can also create new events.To add the details of a new event click on New Button :
</p>

<div ><img src={n3} height="120%" width='100%' ></img></div>
<pre></pre>

<p className='lines'  id='edit' >
Subscribers can also add Faqs/Agenda inside the menu.</p>

<pre></pre>
<p className='lines' >
The Edit button can be used to change and update navigation details  .

Navbars can also be deleted .
</p>

{/* <div><img src={event5} height="100%" width='100%'></img></div>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div> */}

</div>
</div>



   </div>

</div>
</div>)



}

export default Event;