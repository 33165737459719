
import Contentheader from "./content/Contentheader";
import { Link } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './faq.css'

function Faq(){


   const Login=()=>{
      const element = document.getElementById("login");
      element.scrollIntoView();
   }
   const Pass=()=>{
    const element = document.getElementById("pass");
    element.scrollIntoView();
 }
 const Data=()=>{
   const element = document.getElementById("data");
   element.scrollIntoView();
}
const Exhibitor=()=>{
   const element = document.getElementById("exhibitor");
   element.scrollIntoView();
}
const Metting=()=>{
   const element = document.getElementById("meet");
   element.scrollIntoView();
}

const Enable=()=>{
   const element = document.getElementById("email");
   element.scrollIntoView();
}

const Custom=()=>{
   const element = document.getElementById("custo");
   element.scrollIntoView();
}

const Attendee=()=>{
   const element = document.getElementById("attendee");
   element.scrollIntoView();
}
const Analytics=()=>{
   const element = document.getElementById("analytics");
   element.scrollIntoView();
}
return(<div className=' '>



<Contentheader/>
<div className='searchbox'>

<h2 id='headingcontent'>FAQ's </h2>
<pre></pre>

</div>
 
<br></br>
<div className='  container-lg'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-4 '>
  <div className='categories'>Table of Contents</div> 
  <div className='cato' onClick={Login}>How to log in for the first time?</div> 
  <div className='cato' onClick={Pass}  >I forgot my password. How can I reset it?</div>
  <div  className='cato' onClick={Data}>Is my data safe?</div>
  <div  className='cato' onClick={Exhibitor}>How to create exhibitors' pages?</div>
  <div  className='cato' onClick={Metting}>How to define meeting rules?</div>
  <div  className='cato' onClick={Enable}>How to enable and duplicate emails?</div>
  <div  className='cato' onClick={Custom}>How to create custom fields for my Attendees?</div>
  <div  className='cato' onClick={Attendee}>How to create Speakers?</div>
  <div  className='cato' onClick={Analytics}>What information is available in the "Analytics" tab?</div>
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-4'>

<h6 className="question" id="login">How to log in for the first time ?</h6>

<p className="answer">
When the event is about to start, you will receive an invitation email sent by the event's Organizer which will look similar to this one:
The button will lead to the account that was automatically created by the platform when you registered for the event. You will then be prompted to create a new password for your account.
</p><hr></hr>

<h6 className="question" id="pass">I forgot my password. How can I reset it ?</h6>

<p className="answer">
Password reset links are provided by the BizConnect team to assist users in accessing their accounts in the event of a forgotten password. 
These links can only be provided by the BizConnect team and not by the Organizer of the event. To request a password reset link, please fill out the form here. You will be contacted by our team who can assist you. 
If you need to change the password again, you can do so in your account under Settings {'>'} Password. Simply click on Change Password, enter your old password, and define the new one.
Important reminder: When you are accessing and using the password reset link, ensure that you are opening it in either Google Chrome or Mozilla firefox. Copying and pasting the link from one browser to another will not work (ex. copy from Internet Explorer into Chrome). Ensure as well that you do not have any pop-up blockers activated. 
</p><hr></hr>

<h6 className="question" id="data">Is my data safe ?</h6>

<p className="answer">
We take the security and confidentiality of your data and those of your participants very seriously.
 <br></br>
Our watchwords : <br></br>
- You can control the entirety of your data. <br></br>
- You can delete your account at any time. We proceed at the complete removal of your data. <br></br>
- We do not sell or use your data for commercial purposes. <br></br>
- We follow the latest GDPR regulations.
</p><hr></hr>

<h6 className="question" id="exhibitor">How to create exhibitors' pages ?</h6>

<p className="answer">
There are two ways to create the exhibitor pages: <pre></pre>
 
1) Manually<br></br>
From the organizer interface, go to "Event Data Exhibitors" and click on "Add exhibitors". 
Choose "Create manually", indicate the name of the entity and fill in all the information that will appear on its page (at least the required ones). 
<pre></pre>
2) Import via an Excel file<br></br>
From the organizer interface, go to "Event Data Exhibitors" and click on "Add exhibitors". 
Choose "Import via an Excel file", download the proposed file and fill in the specific information to each exhibitor.
Re-import the completed file on the platform.
</p><hr></hr>


<h6 className="question" id="meet">How to define meeting rules ?</h6>

<p className="answer">
Rules are at the heart of the Meeting feature.
 <br></br>
A rule is a couple between a requester group and a host group. <br></br>
A group can be by default (attendees, speakers, exhibitors) or personalized. <br></br>
 

 
Hosts receive the meeting request, and the requester sends the meeting request.
<br></br>
The organizer creates rules to define which group can send a request, and with whom.
For example, if the organizer doesn't have personalized groups and wants everyone to be able to request a meeting with each other, he needs to create 9 rules.
Rules choice is the first required step of the meeting feature setup.
<br></br>

 
Meeting slots and locations depend on these rules.
Note* Each meeting slot and location must be linked to a rule, otherwise it is useless.
<br></br>
Rule creation consists of 3 steps: <br></br>

Select which Groups of people will be able to send a meeting request and to which other Groups <br></br>
Select the Locations where those meetings are to take place <br></br>
Select the time Slots when those meetings are to happen, meaning for each Location selected you can decide on the slots available

</p><hr></hr>


<h6 className="question" id="email">How to enable and duplicate emails ?</h6>

<p className="answer">
Yes, it is possible to activate/duplicate the existing emails on the platform. All you need to do is click on the check box next to the email and then select either of the two options- “Duplicate Email” or “Enable Emails.” 
<br></br>
Duplicating an email lets you create a new email outside the ones already available on the platform by default while enabling emails is important for any email to be sent. No emails will go out on their own unless you “enable” them.
</p><hr></hr>

<h6 className="question" id="custo">How to create custom fields for my Attendees ?</h6>

<p className="answer">
To create a custom field, click on Add custom field, and then define the Label (this is what the custom field will show up as on the People page). Finally, select one of the choices in the drop down menu under Format.
<br></br>
The format of the custom field can be one of these values:
<br></br>
Short text: This value allows you to add a short text in the custom field. This is useful for fields such as Profession or E-mail address. <br></br>
Long text: This allows you to add a longer text in the custom field. This is useful for fields such as Address or Bio.<br></br>
Multiple texts: This allows you to add multiple texts in the custom field. <br></br>
Single choice: This allows you to add multiple options to a list from where the user can only pick one option. This is useful for fields such as Country or City. <br></br>
Multiple choices: This allows you to add multiple options to a list from where the user can pick multiple options. This is useful for fields such as Group member status or Proficiencies.<br></br>
URL: This field allows you to add a URL link. This is useful for fields such as Website or LinkedIn profiles.<br></br>
Number: This allows you to add numbers. This is useful for fields such as Age.<br></br>
Date: This field allows you to add dates. This is useful for fields such as Birthday.<br></br>
Picture: This field allows you to add pictures.
</p><hr></hr>


<h6 className="question" id="attendee">How to create Speakers? ?</h6>

<p className="answer">
A Speaker is the person who will speak during a session of the program: conference, workshop, demonstration, etc. Speakers are added in the same way as other attendees.
An Attendee becomes strictly speaking a Speaker when you associate him to a session of the program
You also have the possibility to create files for your Speakers without giving them access to the platform or linking these files to any profile. 

 
</p><hr></hr>

<h6 className="question" id="analytics">What information is available in the "Analytics" tab ?</h6>

<p className="answer">
- Live data<br></br>
- Most engaged people<br></br>
- Connections {'&'} Usage<br></br>
- Contacts<br></br>
- Sponsors<br></br>
- Exhibitors<br></br>
- Speakers<br></br>
- Sessions<br></br>

</p><hr></hr>

</div>
</div>



   </div>

</div>

    </div>
 );

}
export default Faq;
