import './content.css'
import Contentheader from './Contentheader';
import sp1 from '../images/new_speaker/sp1.png';
import sp2 from '../images/new_speaker/sp2.png';
import sp3 from '../images/new_speaker/sp3.png';

import landing5 from '../images/landing5.png';
import speaker1 from '../images/speaker1.png';
import { useState } from "react";
import Modal from './Modal';

function Speaker(){

   const Custom=()=>{
      const element = document.getElementById("Customize");
      element.scrollIntoView();
   }
   const Announcement=()=>{
    const element = document.getElementById("announcement");
    element.scrollIntoView();
 }
 const Leaderboard=()=>{
    const element = document.getElementById("leaderboard");
    element.scrollIntoView();
 }

 const Reports=()=>{
    const element = document.getElementById("reports");
    element.scrollIntoView();
 }
 const Orders=()=>{
    const element = document.getElementById("orders");
    element.scrollIntoView();
 }
 const Subscription=()=>{
    const element = document.getElementById("subscription");
    element.scrollIntoView();
 }

 const[popup,setpopup]= useState(false);



return(<div>
         {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=5JuPqgFSIb0'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Speaker Room Management </h2>
 <h4 className='stepcontent'>Learn how to  engage in discussions over the virtual space**</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Custom}>Speaker  Room Customization</div> 
  <div className='contentcategories' onClick={Announcement}>Announcement</div>

 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Speaker Room Management</h3>
<p className='lines' >

An event can have auditorium enabled with speaker rooms for communication to which speakers shall have exclusive access.



</p>
<div><img src={sp1} height="100%" width='100%'></img></div>
<p className='lines' id='bold'>
This module shall help you with the elements that can be configured.

<div><img src={sp2} height="100%" width='100%'></img></div>

</p>

<p className='lines' id='Featuress'>
Auditorium Configuration :<br></br>
1.Organizers can customize the names of the auditorium as per their needs.
 eg: auditorium_2_29 to configure<br></br>
2.Subscribers can enter the day and Title for the meeting <br></br>
3.They can choose the Link type whether it is 8*8 ,meet or zoom link
 The link/ Url for the meeting<br></br>
3.Position for meeting links say for eg:1,2 or 3 

</p>
<div><img src={sp3} height="100%" width='100%'></img></div>



<li className='lines_' id='Customize'>Speaker  Room UI customization:</li>

<li className='lines'>Select and upload a Background Image </li>
<li className='lines'> 	Select a text Color</li>
<li className='lines'>Select a Background Color for the bar </li>

<pre></pre>

<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>



</div>
</div>



   </div>

</div>
</div>)



}

export default Speaker;