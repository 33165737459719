import "./pricing.css";
import React, { useState } from "react";
import "tippy.js/dist/tippy.css";
import webinarSelect from "../images/WebinarSelect.png";
import virtualEventSelect from "../images/virtualEventSelect.png";
import logo from "../images/logo.png";

import Tippy from "@tippyjs/react";
import { Link, useParams } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function Pricing() {
  const { pageNo } = useParams();
  const [page, setPage] = useState(pageNo ? pageNo : 0);
  const [moreDetails, setMoreDetails] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const [moreDetailsWebinar, setMoreDetailsWebinar] = useState(false);
  const [isYearlyWebinar, setIsYearlyWebinar] = useState(false);

  function Webinar() {
    return (
      <div>
        <h1
          Style="text-align: center; font-size: 37px;"
          className="fw-bold mt-5 "
          id="monthly"
        >
          Monthly Subscription Cost
        </h1>
        <h5
          className="mb-5"
          Style="text-align:center; color:rgba(245,135,50,255) "
        >
          Get 20% discount for Annual Subscription
        </h5>
        <br />
        <div className="billcontainer mb-4 ">
          <span className="form-check form-switch billcontainer_span ">
            <input
              Style="cursor: pointer; "
              checked={isYearlyWebinar}
              onInput={() => {
                setIsYearlyWebinar(!isYearlyWebinar);
              }}
              className="form-check-input shadow-none"
              type="checkbox"
              id="flexSwitchCheckDefault"
            />
          </span>
          <p Style="font-size: 20px; margin-bottom:0;font-weight: 500;">
            Bill Annually -
          </p>
          <p Style="font-size: 20px; margin-bottom:0; font-weight: 500; color:rgba(245,135,50,255) ;">
            {" "}
            Save 20%
          </p>
        </div>
        <br />

        <div Style="  " className=" mx-lg-5 webinar_container  ">
          <div className="row border-bottom mx-lg-5 mx-md-3 mx-sm-2 mx-1 border price_container_new ">
            <div className="col-lg border-end  pb-3 Hover_turn  " Style="">
              <h4
                Style="    
        place-items: center;"
              >
                <i className="bi bi-star-fill me-1"></i>Features
              </h4>
            </div>
            <div className="col-lg border-end pb-3 verticalCenter ">
              <div className="colchild  ">
                <p className="childheading px-lg-4 px-md-3 px-1 fw-bold">
                  Webinar Free
                </p>
              </div>
              <p className="childcontent px-lg-4 ">
                For small conferences and workshops with 20 attendees per month
                with a Auditorium &amp; booth included along with the choice of
                your event name in bizconnect URL.
              </p>
              <div className="mt-4 mb-5 ">
                <p className="childprice mx-lg-4 ">
                  <span className="starts_at"></span> <br />
                  <p id="free-plan-price_css" className="mx-lg-5">
                    <span className="dollar"></span>
                    <span className="main_price  " id="free-plan-price">
                      Free
                    </span>
                    <span className="wild_card"></span>
                    <p className="mx-lg-4 nagative_margin">
                      <span className="duration " id="change_onCheck"></span>
                      <span className="text-white">*</span>
                    </p>
                  </p>
                </p>
                <form
                  action="{{ route('techbot-subscription-plan-webinar-free') }}"
                  method="post"
                  id="techbot-subscription-plan-free"
                  hidden
                >
                  @csrf
                </form>
                <div
                  className="span"
                  onclick="freeSubscriptionClicked()"
                  Style="     border: 1.5px solid  rgb(30 99 164 / 75%);
        border-radius: 5px;
        font-weight: 500;
        color:  rgb(30 99 164 / 75%);
        padding: 6px;
        padding-left: 16px;
        padding-right: 16px;
        width: max-content;
        text-align: center;
        display: block;
        font-weight: normal;
        margin: auto;"
                >
                  <a
                    href="https://subscription.bizconnect.events/signup"
                    className="updrage_now"
                  >
                    UPGRADE NOW
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg border-end pb-3 verticalCenter ">
              <div className="colchild ">
                <p className="childheading px-lg-4 px-md-3 px-1 fw-bold">
                  Webinar Single
                </p>
              </div>
              <p className="childcontent px-lg-4 ">
                For small interactive events and webinars with 50 attendees per
                month. Engage with your audience and host them on the platform
                in any language of your choice.
              </p>
              <div className="mt-4 mb-5 ">
                <p className="childprice mx-lg-4 ">
                  <span className="starts_at">Starts at</span> <br />
                  <p id="express-plan-price_css" className="mx-lg-5">
                    <span className="dollar">$</span>
                    <span className="main_price  " id="express-plan-price">
                      {isYearlyWebinar ? "---" : 70}
                    </span>
                    <span className="wild_card">*</span>
                    <p className="mx-lg-4 nagative_margin ">
                      <span className="duration">/webinar</span>
                      <span className="">*</span>
                    </p>
                  </p>
                </p>
                <div
                  className={`span ${
                    isYearlyWebinar ? " d-none " : "d-block"
                  } `}
                  onclick="expressSubscriptionClicked()"
                  Style="    border: 1.5px solid  rgb(30 99 164 / 75%);
        border-radius: 5px;
        font-weight: 500;
        color:  rgb(30 99 164 / 75%);
        padding: 6px;
        padding-left: 16px;
        padding-right: 16px;
        width: max-content;
        text-align: center;
        font-weight: normal;
        margin: auto;"
                >
                  <a
                    href="https://subscription.bizconnect.events/signup"
                    className="updrage_now"
                  >
                    UPGRADE NOW
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg pb-3 verticalCenter most_popular_container "
              Style="border-top-right-radius: 20px;border-bottom-right-radius: 20px;"
            >
              <span className="most_popular text-center">popular</span>
              <div className="colchild ">
                <p
                  className="childheading px-lg-4 px-md-3 px-1 fw-bold "
                  Style="display: inline-flex; justify-content: space-between;align-items: center"
                >
                  Webinar Monthly
                </p>
              </div>
              <p className="childcontent px-lg-4 ">
                For regular events and fairs with 200 attendees per month.
                Expand your connections by networking with attendees that match
                your interests.
              </p>
              <div className="mt-4 mb-5 ">
                <p className="childprice mx-lg-4 ">
                  <span className="starts_at">Starts at</span> <br />
                  <p id="premium-plan-price_css" className="mx-lg-5">
                    <span className="dollar">$</span>
                    <span className="main_price" id="premium-plan-price">
                      {isYearlyWebinar ? 1920 : 200}
                    </span>
                    <span className="wild_card">*</span>
                    <p className="mx-lg-4  nagative_margin ">
                      <span className="duration" id="change_onCheck2">
                        {isYearlyWebinar ? "/year" : "/month"}
                      </span>
                      <span className="">*</span>
                    </p>
                  </p>
                </p>

                <div
                  className="span "
                  onclick="premiumSubscriptionClicked()"
                  Style="    border: 1.5px solid  rgb(30 99 164 / 75%);
        border-radius: 5px;
        font-weight: 500;
        color:  rgb(30 99 164 / 75%);
        padding: 6px;
        padding-left: 16px;
        padding-right: 16px;
        width: max-content;
        text-align: center;
        display: block;
        font-weight: normal;
        margin: auto;"
                >
                  <a
                    href="https://subscription.bizconnect.events/signup"
                    className="updrage_now"
                  >
                    UPGRADE NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="hide  mt-lg-4 mx-lg-5 px-lg-1 ">
            <div className="row">
              <div className="col heading heading border-end border-bottom">
                <i className="bi bi-star-fill"></i> Features
              </div>
              <div className="col heading border-end border-bottom">
                <i className="bi bi-hand-thumbs-up-fill"></i>
                <br /> Free
              </div>
              <div className="col heading border-end border-bottom">
                <i className="bi bi-mortarboard-fill"></i> Express
              </div>
              <div className="col heading border-end border-bottom">
                <i className="fas fa-award   "></i> Premium
              </div>
              <i className="fa-solid fa-award-simple"></i>
            </div>

            <button
              id="show_more"
              onClick={() => {
                setMoreDetailsWebinar(true);
              }}
              className={`show_more_less ${
                moreDetailsWebinar ? " d-none " : ""
              } `}
            >
              More...
            </button>
            <div
              id=" "
              className={` ${!moreDetailsWebinar ? " d-none " : ""} `}
            >
              <div className="row ">
                <div className="col-12 section">
                  <i className="fas fa-chalkboard-teacher"></i> Event &amp;
                  Attendees
                </div>
              </div>

              <div className="row">
                <div className="col  no_of_sing border-end border-bottom">
                  Number of single day event
                </div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">
                  Unlimited
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Number of attendees
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  20
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  50
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  200
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Additional 25 attendees
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Purchase additional attendees. Any remains will carry
                          forward for following months. ( Purchase from Add-ons)
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing  border-end border-bottom">
                  $20
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $20
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $20
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Max days per event
                </div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">2</div>
                <div className="col no_of_sing border-end border-bottom">5</div>
              </div>

              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Language options
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>Terms applied</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>

              <div className="row section ">
                <div className="col-12   ps-0">
                  <i className="bi bi-globe2"></i> URL &amp; Whitelabels Section
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Default
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3">Default URL</Popover.Header>
                        <Popover.Body>
                          bizconnect.events/youreventname
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Whitelabel per event
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3">
                          eventname.yourdomain.com{" "}
                        </Popover.Header>
                        <Popover.Body>
                          ( Example: virtual.xyz.com ) - Purchase from Add-ons
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $200
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $200
                </div>
              </div>
              <div className="row">
                <div
                  className="col no_of_sing border-end border-bottom"
                  Style="width: 200px;"
                >
                  Whitelabel per annum
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3">
                          name.yourdomain.com/youreventname
                        </Popover.Header>
                        <Popover.Body>
                          Example: virtual.xyz.com/annualday. Every
                          event/youreventname will be different. Purchase from
                          Add-ons.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $500
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $500
                </div>
              </div>

              <div className="text-center "> </div>

              <button
                onClick={() => {
                  setMoreDetailsWebinar(false);
                }}
                id="hidebutton"
                className="show_more_less"
              >
                Less ...
              </button>
            </div>
          </div>
        </div>
        <pre></pre>
      </div>
    );
  }

  function NewVirtualEventHibridEvent() {
    return (
      <div>
        <h1
          Style={"font-size: 37px;"}
          className="fw-bold mt-5 text-center "
          id="monthly"
        >
          Monthly Subscription Cost
          <OverlayTrigger
            trigger="hover"
            key={"top"}
            placement={"right"}
            overlay={
              <Popover id={`popover-positioned-${"top"}`}>
                <Popover.Header as="h3"></Popover.Header>
                <Popover.Body>
                  Pricing Page for customers to view the required features and
                  plans , and to host their events efficiently
                </Popover.Body>
              </Popover>
            }
          >
            <i className="fas fa-info-circle ms-1 "></i>
          </OverlayTrigger>
        </h1>

        <h5
          className="mb-4"
          Style={"text-align:center; color:rgba(245,135,50,255) "}
        >
          Get 20% discount for Annual Subscription
        </h5>
        <br />
        <div className="billcontainer mb-1">
          <span className="form-check form-switch billcontainer_span ">
            <input
              Style={"cursor: pointer; "}
              oninput="billAnuallyClicked()"
              className="form-check-input shadow-none"
              type="checkbox"
              checked={isYearly}
              onInput={() => {
                setIsYearly(!isYearly);
              }}
              id="flexSwitchCheckDefault"
            />
          </span>
          <p Style={"font-size: 20px; margin-bottom:0;font-weight: 500;"}>
            Bill Annually -
          </p>
          <p
            Style={
              "font-size: 20px; margin-bottom:0; font-weight: 500; color:rgba(245,135,50,255) ;"
            }
          >
            {" "}
            Save 20%
          </p>
        </div>
        <br />
        <div
          Style={" border-radius: 20px; "}
          className="container-sm border my-1 subscription_container "
        >
          <div className="row border-bottom">
            <div
              className="col-lg border-end  pb-3 Hover_turn "
              Style={"display: grid;place-items: center"}
            >
              <h4 Style={"place-items: center;"}>
                <i className="bi bi-star-fill me-1"></i>Features
              </h4>
            </div>
            <div className="col-lg border-end pb-3 verticalCenter ">
              <div
                className="row coffe border-end border-bottom "
                Style={
                  "background-color:rgba(249,250,250,255) ; justify-content:center; "
                }
              >
                <span> Tester </span>
              </div>
              <div className="colchild ">
                <p className="childheading px-lg-4 px-md-3 px-1 fw-bold">
                  Free
                </p>
              </div>
              <p className="childcontent px-lg-2  ">
                For small conferences and workshops with 50 attendees per month
                with a Auditorium &amp; booth included along with the choice of
                your event name in bizconnect URL.
              </p>
              <div className="mt-4 mb-5">
                <p className="childprice mx-lg-4 ">
                  <span className="starts_at"></span> <br />
                  <p id="free-plan-price_css" className="mx-lg-5">
                    <span className="dollar"></span>
                    <span className="main_price  " id="free-plan-price">
                      Free
                    </span>
                    <span className="wild_card"></span>
                    <p className="mx-lg-4 nagative_margin">
                      <span className="duration " id="change_onCheck"></span>
                      <span className="text-white">*</span>
                    </p>
                  </p>
                </p>

                <form
                  action="{{ route('techbot-subscription-plan-free') }}"
                  method="post"
                  id="techbot-subscription-plan-free"
                  hidden
                >
                  @csrf
                </form>

                <div>
                  <div className="span " onclick="premiumSubscriptionClicked()">
                    <a
                      href="https://subscription.bizconnect.events/signup"
                      className="updrage_now"
                    >
                      UPGRADE NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg border-end pb-3 verticalCenter  ">
              <div
                className="row coffe border-end border-bottom "
                Style={
                  "background-color:rgba(249,250,250,255) ; justify-content:center; "
                }
              >
                <span> Small </span>
              </div>
              <div className="colchild ">
                <p className="childheading px-lg-4 px-md-3 px-1 fw-bold">
                  Express
                </p>
              </div>
              <p className="childcontent px-lg-2  ">
                For small interactive events and webinars with 200 attendees per
                month. Engage with your audience and host them on the platform
                in any language of your choice.
              </p>

              <div className="mt-4 mb-5">
                <p className="childprice mx-lg-4 ">
                  <span className="starts_at">Starts at</span> <br />
                  <p id="express-plan-price_css" className="mx-lg-5">
                    <span className="dollar">$</span>
                    <span className="main_price  " id="express-plan-price">
                      {isYearly ? 6720 : 700}
                    </span>
                    <span className="wild_card">*</span>
                    <p className="mx-lg-4 nagative_margin ">
                      <span className="duration" id="change_onCheck3">
                        {isYearly ? "/year" : "/month"}
                      </span>
                      <span className="">*</span>
                    </p>
                  </p>
                </p>
                <div>
                  <div className="span " onclick="premiumSubscriptionClicked()">
                    <a
                      href="https://subscription.bizconnect.events/signup"
                      className="updrage_now"
                    >
                      UPGRADE NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg pb-3 verticalCenter most_popular_container ">
              <div
                className="row coffe border-end border-bottom "
                Style={
                  "background-color:rgba(249,250,250,255) ; justify-content:center; "
                }
              >
                <span className="most_popular subscription">Popular</span>
                <span> Medium </span>
              </div>

              <div className="colchild ">
                <p
                  className="childheading px-lg-4 px-md-3 px-1 fw-bold  "
                  Style={
                    "display: inline-flex; justify-content: space-between;align-items: center"
                  }
                >
                  Premium
                </p>
              </div>
              <p className="childcontent px-lg-2  ">
                For regular events and fairs with 500 attendees per month.
                Expand your connections by networking with attendees that match
                your interests.
              </p>

              <div className=" mt-4 mb-5">
                <p className="childprice mx-lg-4 ">
                  <span className="starts_at">Starts at</span> <br />
                  <p id="premium-plan-price_css" className="mx-lg-4">
                    <span className="dollar">$</span>
                    <span className="main_price" id="premium-plan-price">
                      {isYearly ? 9600 : 1000}
                    </span>
                    <span className="wild_card">*</span>
                    <p className="mx-lg-4  nagative_margin ">
                      <span className="duration" id="change_onCheck2">
                        {isYearly ? "/year" : "/month"}
                      </span>
                      <span className="">*</span>
                    </p>
                  </p>
                </p>
                <div>
                  <div className="span " onclick="premiumSubscriptionClicked()">
                    <a
                      href="https://subscription.bizconnect.events/signup"
                      className="updrage_now"
                    >
                      UPGRADE NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg pb-3 border-start  verticalCenter"
              Style={"border-top-right-radius: 20px;"}
            >
              <div
                className="row coffe  border-bottom "
                Style={
                  "background-color:rgba(249,250,250,255) ; justify-content:center; border-top-right-radius: 20px; "
                }
              >
                <span>Large </span>
              </div>

              <div className="colchild ">
                <p className="childheading px-lg-4 px-md-3 px-1 fw-bold">
                  Custom
                </p>
              </div>
              <p className="childcontentlast px-lg-2  ">
                Expos and summits with 1000 attendees per month. Build the most
                visually striking event with moving banners and customize it in
                any way you want. Talk to us to explore
              </p>

              <div className="mt-4 mb-5">
                <p className="childprice mx-lg-4 ">
                  <span className="starts_at">Starts at</span> <br />
                  <p id="premium-plan-price_css" className="mx-lg-5">
                    <span className="dollar">$</span>
                    <span
                      className="main_price"
                      id="custom-plan-price"
                      Style={"color:transparent"}
                    >
                      .
                    </span>
                    <span className="wild_card">*</span>
                    <p className="mx-lg-4  nagative_margin ">
                      <span className="duration" id="change_onCheck4">
                        {isYearly ? "/year" : "/month"}
                      </span>
                      <span className="">*</span>
                    </p>
                  </p>
                </p>
                <div>
                  {/* todo: change color */}
                  <div
                    className="span spanLast"
                    Style={
                      "border-color: rgba(245,135,50,255);color:rgba(245,135,50,255);"
                    }
                  >
                    <a
                      href="https://subscription.bizconnect.events/signup"
                      className="updrage_now"
                    >
                    TALK WITH US
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hide">
            <div className="row">
              <div className="col heading heading border-end border-bottom">
                <i className="bi bi-star-fill"></i> Features
              </div>
              <div className="col heading border-end border-bottom">
                <i className="bi bi-hand-thumbs-up-fill"></i>Free
              </div>
              <div className="col heading border-end border-bottom">
                <i className="bi bi-mortarboard-fill"></i> Express
              </div>
              <div className="col heading border-end border-bottom">
                <i className="fas fa-award"></i> Premium
              </div>
              <i className="fa-solid fa-award-simple"></i>
              <div className="col heading border-end border-bottom">
                <i className="fas fa-trophy"></i> Custom
              </div>
            </div>
            <button
              id="show_more"
              onClick={() => {
                setMoreDetails(true);
              }}
              className={`show_more_less ${moreDetails ? " d-none " : ""} `}
            >
              More ...
            </button>
            <div
              id=" "
              className={`clickOnshow_more ${!moreDetails ? " d-none " : ""} `}
            >
              <div className="row ">
                <div className="col-12 section">
                  <i className="fas fa-chalkboard-teacher ps-2"></i> Event &amp;
                  Attendees
                </div>
              </div>

              <div className="row">
                <div className="col  no_of_sing border-end border-bottom">
                  Number of single day event
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Unlimited
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Unlimited
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Unlimited
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Talk to us
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Number of attendees
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  50
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  200
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  500
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  1000
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Additional 25 attendees
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Purchase additional attendees. Any remains will carry
                          forward for following months. ( Purchase from Add-ons)
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing  border-end border-bottom">
                  $20
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $20
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $20
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Max days per event
                </div>
                <div className="col no_of_sing border-end border-bottom">2</div>
                <div className="col no_of_sing border-end border-bottom">5</div>
                <div className="col no_of_sing border-end border-bottom">
                  10
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Custom
                </div>
              </div>

              <div className="row">
                <div
                  className="col no_of_sing border-end border-bottom  "
                  title="Add more Halls for Template of Different Preview "
                >
                  <span>Language options</span>

                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>terms applied </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>

              <div className="row section ">
                <div className="col-12   ">
                  <i className="bi bi-globe2"></i> URL &amp;Whitelabels Section
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Default
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          bizconnect.events/youreventname
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom  ">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Whitelabel per event
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          ( Example: virtual.xyz.com ) - Purchase from Add-ons
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $200
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $200
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $200
                </div>
              </div>
              <div className="row">
                <div
                  className="col no_of_sing border-end border-bottom"
                  Style={"width: 200px;"}
                >
                  Whitelabel per annum
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Example: virtual.xyz.com/annualday. Every
                          event/youreventname will be different. Purchase from
                          Add-ons.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $500
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $500
                </div>
              </div>

              <div className="row section ">
                <div className="col-12 ">
                  <i className="bi bi-badge-3d-fill"></i> 3D &amp;360° Walkthrus
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Walkthrough
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Exterior - Entrance ~ Default Free / Branded $ 100
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Walkthrough
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          (Exterior - Entrance) (Add Yours)
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  Cost
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Cost
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Cost
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Branded Lobby Walkthrough
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>Max to 3 doors $100/Event</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  360 degree
                </div>
                <div className="col no_of_sing border-end border-bottom">-</div>
                <div className="col no_of_sing border-end border-bottom">-</div>
                <div className="col no_of_sing border-end border-bottom">-</div>
                <div className="col no_of_sing border-end border-bottom">
                  Talk with us
                </div>
              </div>
              <div className="row section ">
                <div className="col-12 ">
                  <i className="bi bi-telephone-fill"></i> Calls (Video &amp;
                  Audio) &amp; <i className="bi bi-chat-dots-fill"></i> Chat
                </div>
              </div>

              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Chat (Single)
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Built in or add third party (embed iFrame)
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Video Call (Single)
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Built in or add third party URL
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  One2One Chat
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Built in or add third party embed iFrame
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Group Chat
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          (emoji &amp;GIF expressions) (Built in or add third
                          party embed iFrame)
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>

              <div className="row section ">
                <div className="col-12 ">
                  <i className="bi bi-speaker-fill"></i> Lobby
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Templates
                </div>
                <div className="col no_of_sing border-end border-bottom">2</div>
                <div className="col no_of_sing border-end border-bottom">3</div>
                <div className="col no_of_sing border-end border-bottom">5</div>
                <div className="col no_of_sing border-end border-bottom">
                  Special Templates
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Static
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>Default Templates</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Zoom Out Hanging Banner
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          Yes Based on Template Chosen
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Static/GIF Hanging Banner
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Buy Custom Landing Template
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Special template
                </div>
              </div>

              <div className="row section ">
                <div className="col-12 ">
                  <i className="bi bi-easel2-fill"></i> Auditorium
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Number of Auditoriums
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          (Multiple Tracks) (Select your live streaming -
                          YouTube, Vimeo (Add URL player) or request )
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">
                  Multiple
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Multiple
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Multiple
                </div>
              </div>

              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Banner
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>As per template</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Banner GIF
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>As per template</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom"></div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom justify-content-start ">
                  DIY Speakers
                  <span
                    data-toggle="tooltip"
                    title="DIY Speakers/Session Management/Agenda "
                  >
                    ...
                  </span>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Speakers Lounge
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Language Interpreter
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          (English + Your choice) (Interpreter cost not
                          included) ($200/Event)
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  $200
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $200
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Q&amp;A
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>(Text Form)</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Buy Custom Auditorium Template
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $75
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $75
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $75
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Special Template
                </div>
              </div>

              <div className="row section ">
                <div className="col-12 ">
                  <i className="fas fa-baby-carriage"></i> Exhibition
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Halls
                </div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">2</div>
                <div className="col no_of_sing border-end border-bottom">5</div>
                <div className="col no_of_sing border-end border-bottom"> </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Exhibition Booth
                </div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">3</div>
                <div className="col no_of_sing border-end border-bottom">5</div>
                <div className="col no_of_sing border-end border-bottom">
                  Talk with Us
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Extra Booth/Event
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Talk with Us
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Per Chat
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $25
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $8
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $8
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $8
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Per Video
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $10
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $10
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Buy Custom Hall Template
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $75
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $75
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $75
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Special template
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Buy Custom Booth Template
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Special template
                </div>
              </div>
              <div className="row section ">
                <div className="col-12 ">
                  <i className="bi bi-people-fill"></i>
                  <i className="bi bi-people-fill"></i> Engagement
                </div>
              </div>

              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Leaderboard
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Business card exchange
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Free Games
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>By Default</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">2</div>
                <div className="col no_of_sing border-end border-bottom">3</div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Games with Leaderboard/Event
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  $50
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $50
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Talk with us
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Photo Booth/Month
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  $100
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Photo Gallery
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Announcement
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Download
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>Event Briefcase</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row section ">
                <div className="col-12 ">
                  <i className="bi bi-people-fill"></i> Networking
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Templates
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">2</div>
                <div className="col no_of_sing border-end border-bottom">
                  Talk to Us
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Business card exchange
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  User profile
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Networking Room
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">1</div>
                <div className="col no_of_sing border-end border-bottom">2</div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Buy Custom Networking Template
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  $75
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Special template
                </div>
              </div>

              <div className="row section ">
                <div className="col-12 ">
                  <i className="bi bi-person-lines-fill"></i> Support
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Onboarding Executive
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>
                          "1st Event Free Tech Support Post 1st Event - Per hr $
                          75"
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom"> </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  Talk to us
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Mails
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Chat
                </div>
                <div className="col no_of_sing border-end border-bottom"></div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row section ">
                <div className="col-12 ">
                  <i className="fas fa-baby-carriage"></i> Event Production
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Video Player
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>Integrate Yours</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>
              <div className="row">
                <div className="col no_of_sing border-end border-bottom">
                  Show Running
                  <OverlayTrigger
                    trigger="hover"
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-${"top"}`}>
                        <Popover.Header as="h3"></Popover.Header>
                        <Popover.Body>DIY</Popover.Body>
                      </Popover>
                    }
                  >
                    <i className="fas fa-info-circle me-1 "></i>
                  </OverlayTrigger>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
                <div className="col no_of_sing border-end border-bottom">
                  <span
                    className="iconify"
                    data-icon="heroicons-solid:check"
                  ></span>
                </div>
              </div>

              <div className="text-center Note ">
                <div className="d-flex">
                  <div>
                    <p className=""> NOTE:</p>
                  </div>
                  <div>
                    <p> More free Templates will be added every month</p>
                    <p> Buy special templates with additional cost </p>
                  </div>
                </div>
              </div>
              <button
                id="hidebutton"
                onClick={() => {
                  setMoreDetails(false);
                }}
                className="show_more_less"
              >
                Less...
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function CardSelection({ src, page, title, date, info, className, link }) {
    function pageDisplay() {
      setPage(page);
    }

    return (
      <div className={`cardSelection mx-5 my-5 ${className}`}>
        <img
          src={src}
          draggable="false"
          className="cs_image"
          alt="webinar Selection"
        />

        <h5 className="cs_title">{title}</h5>
        <div className="cs_date">{date}</div>
        <p className="cs_info">{info}</p>
        <div className="d-flex justify-content-between mx-1  ">
          <button className="cs_info_page">
            <a
              target={"_blank"}
              rel="noreferrer"
              className="text-decoration-none  "
              href={link}
            >
              read more ...
            </a>{" "}
          </button>
          <button className="cs_pricing_page" onClick={pageDisplay}>
            Pricing...
          </button>
        </div>
      </div>
    );
  }

  let today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  today = dd + " - " + mm + " - " + yyyy;

  return (
    <>
      <div className="navbar  ">
        <div className="topleft">
          <div className="ms-3">
            <img src={logo} alt="logo" height="100%" width="70%"></img>
          </div>

          <div>
            <Tippy className="tippy" content=" Go To Home">
              <Link to="/home" className="home ">
                <i className="bi bi-house-fill homebtn"></i>
              </Link>
            </Tippy>
          </div>
        </div>

        <div className=" ">
          <Link to="/calculator" className="topbuttons  ">
            <i className="bi topicons bi-calculator-fill"></i> Calculator
          </Link>
          {/* https://bizconnectevents.com/subscriber */}

          <a
            href="https://subscription.bizconnect.events/signup"
            id="subscribe"
            className=" topbuttons  "
          >
            Subscribe
          </a>
        </div>
      </div>

      {page === 0 ? (
        <section className="d-lg-flex d-md-flex d-sm-flex mb-5 justify-content-center mt-lg-5  align-items-center">
          <div>
            <CardSelection
              src={virtualEventSelect}
              link={"https://bizconnect.events/virtualevent.html"}
              title={"Virtual & Hybrid Events"}
              date={today}
              info={
                "We have a plan for every budget and need. Get your own customized Virtual and Hybrid Events Platform including B2B Meeting Scheduler , Matchmaking Tool, Business Card Exchange, Metaverse Environment and much more."
              }
              page={2}
            />
          </div>
          <div>
            <CardSelection
              className="webinar"
              link={"https://bizconnect.events/webinarevent.html"}
              src={webinarSelect}
              title={"Webinar"}
              date={today}
              page={1}
              info={
                "We offer countless possibilites to host and create your own Webinar with 1:1 Chat, Polls , Handouts, Q&A , Business Card Exchange and much more. Choose your Package effortlessly."
              }
            />
          </div>
        </section>
      ) : (
        <>{page == 1 ? <Webinar /> : <NewVirtualEventHibridEvent />}</>
      )}
    </>
  );
}

export default Pricing;
