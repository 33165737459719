import './content.css'
import Contentheader from './Contentheader';
import l1 from '../images/new_lobby/l1.png';
import l2 from '../images/new_lobby/l2.png';
import l3 from '../images/new_lobby/l3.png';
import l4 from '../images/new_lobby/l4.png';
import l5 from '../images/new_lobby/l5.png';
import l6 from '../images/new_lobby/l6.png';

import { useState } from "react";
import Modal from './Modal';


function Lobby(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Preview=()=>{
    const element = document.getElementById("landing1");
    element.scrollIntoView();
 }
 const Editlink=()=>{
    const element = document.getElementById("landing2");
    element.scrollIntoView();
 }

 const Disclaimer=()=>{
    const element = document.getElementById("landing4");
    element.scrollIntoView();
 }

 const[popup,setpopup]= useState(false);




return(<div>
    {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Lobby Management </h2>
 <h4 className='stepcontent'>Learn how to  configure Lobby Management</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Lobby Management</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Lobby Management</h3>
<p className='lines' >

Virtual Lobby :Lobby is the gateway of access to the different zones of the  virtual event. Once the attendees enter into the lobby they can view and proceed to the various zones of the event .These zones are exhibition halls, auditoriums, photo booths etc.               


</p>

<div><img src={l1} height="100%" width='100%'></img></div>




<li className='lines_' id='Customize'>Lobby Management helps  configure and customize the various elements that show up in the lobby template:</li>


<li className='lines'>Users can view the lobby template </li>
<li className='lines'> Download the lobby template to their PC. </li>
<li className='lines'>Configure Chat. </li>
<li className='lines'>Set the zones to be displayed on the lobby page. </li>
<li className='lines'>Customize the lobby elements </li>
<div><img src={l2} height="100%" width='100%'></img></div>
<br></br>
<div><img src={l3} height="100%" width='100%'></img></div>
<div><img src={l4} height="100%" width='100%'></img></div>
<div><img src={l5} height="100%" width='100%'></img></div>
<div><img src={l6} height="100%" width='100%'></img></div>

<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>







</div>
</div>



   </div>

</div>
<br></br><br></br><br></br><br></br>
</div>)



}

export default Lobby;