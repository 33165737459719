import "./maincontent.css";
import { Link } from "react-router-dom";
import book from "./images/book.png";

import "tippy.js/dist/tippy.css";

import contact from "./images/contact.png";

import { useEffect, useState } from "react";

function Content({ topic }) {
  const [postCardData, setPostCardData] = useState([]);

  useEffect(() => {
    fetch("https://admin.knowledgebase.bizconnect.events/api/topics")
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => a.position - b.position);
        console.log("is sorted", data);
        setPostCardData(data);
        // console.log(data);
      });
  }, []);

  const PostCard = ({ _id, title, starter_description, logoUrl }) => {
    return (
      <Link
        to={`/module/${_id}`}
        className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 "
        style={{ textDecoration: "none" }}
      >
        <div className="cont_container">
          <div className="d-flex align-items-center">
            <img
              alt="img"
              src={"https://admin.knowledgebase.bizconnect.events/" + logoUrl}
              className="card_image"
            />
            <h5 className="contheading text-dark ms-2 px-2 ">{title}</h5>
          </div>
          <div className="">
            <p className="contdetails ms-2 mb-0 mt-2">{starter_description}</p>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className=" container-fluid ">
      <div className="row expolretopicsrow">
        <div className="col-md-6 explore ">
          <p className="topics">
            <img alt="img" className="bookimage" height="60px" src={book}></img>
            Explore Topics
          </p>
        </div>
        <div className="col-md-6">
          <p className="descp">
            We did our best to cover all topics related to our product. Each
            section have detailed info of topic in each category.
          </p>
        </div>

        <pre></pre>
      </div>
      <pre></pre>

      <div className="row g-3 ">
        {postCardData.map(
          ({ _id, title, logo, topic_for, starter_description }) => {
            console.log(topic === topic_for);
            if (topic === topic_for) {
              return (
                <PostCard
                  key={_id}
                  _id={_id}
                  title={title}
                  logoUrl={logo}
                  starter_description={starter_description}
                />
              );
            } else {
            }
          }
        )}
      </div>
      <br></br>
      <br></br>
      <div class="row  ">
        <div className="col-2"></div>

        <div className="col-md-3 text-center">
          <img
            alt="img"
            className="write_image"
            src={contact}
            height="100%"
            width="140%"
          ></img>
        </div>
        <div className="col-1"></div>
        <div className="col-md-4 mt-4 sidecontent">
          <h1>Contact Us</h1>
          <p>
            If you have question which is beyond this knowledgebase Kindly
            contact us
          </p>
          <pre></pre>
          <Link to="/request" className="writebutton">
            Write to us now
          </Link>
        </div>
      </div>

      <br></br>
    </div>
  );
}

export default Content;
