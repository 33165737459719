import './content.css'
import Contentheader from './Contentheader';
import addatt from '../images/addatt.png';
import ah1 from '../images/ah1.png';
import ah2 from '../images/ah2.png';
import ah3 from '../images/ah3.png';
import { useState } from "react";
import Modal from './Modal';

function Additionalhalls(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }

   const[popup,setpopup]= useState(false);


return(<div> {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MAv5iOP1f5k'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Additional Halls</h2>
 <h4 className='stepcontent'>Learn about Additional Halls</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Content</div> 
  <div className='contentcategories' onClick={Features}>Additional Halls</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Additional Halls</h3>
<p className='lines' >
A subscriber can purchase additional set of exhibition halls based on his subscription type.
Each set he purchases , he will be charged.The charges are already mentioned in the pricing page.
<pre></pre>


- The subscriber<br></br>
- Event<br></br>
- Type<br></br>
- Additional halls<br></br>
- Date <br></br>


The subscriber first selects and event:



</p>

<div ><img src={ah1} height="120%" width='100%' ></img></div>

<p className='lines'><pre></pre>
In the add-ons page the subscriber selects an event and chooses the number of exhibition halls required as:

</p>
<div ><img src={ah2} height="120%" width='100%' ></img></div>
<p className='lines'><pre></pre>
On every order confirmed after payment ,the list appears in here.


</p>
<div ><img src={ah3} height="120%" width='100%' ></img></div>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

</div>
<br></br>
</div>



   </div>

</div>
<br></br><br></br><br></br>
</div>)



}

export default Additionalhalls;