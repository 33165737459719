import './content.css'
import Contentheader from './Contentheader';
import landing1 from '../images/landing1.png';
import landing2 from '../images/landing2.png';
import landing3 from '../images/landing3.png';
import landing4 from '../images/landing4.png';
import landing5 from '../images/landing5.png';
import { useState } from "react";
import Modal from './Modal';

function Announcement(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Preview=()=>{
    const element = document.getElementById("landing1");
    element.scrollIntoView();
 }
 const Editlink=()=>{
    const element = document.getElementById("landing2");
    element.scrollIntoView();
 }

 const Disclaimer=()=>{
    const element = document.getElementById("landing4");
    element.scrollIntoView();
 }

 const[popup,setpopup]= useState(false);




return(<div>  {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MAv5iOP1f5k'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Announcement Management </h2>
 <h4 className='stepcontent'>Learn how to  engage in discussions over the virtual space</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Categories</div> 
  <div className='contentcategories' onClick={Features}>Announcement Management</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Announcement Management</h3>
<p className='lines' >

This module allows users to add Announcements for a particular event as the event is live.The announcement shows up on every page or custom pages as defined by the organizer.
<pre></pre>
Organizers can add a subject or a title to their announcement.
They can also add an Announcement Message using HTML editor.



</p>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

<br></br><br></br><br></br>

</div>
</div>



   </div>

</div><br></br><br></br>
</div>)



}

export default Announcement;