import './content.css'
import Contentheader from './Contentheader';
import addatt from '../images/addatt.png';
import ab1 from '../images/ab1.png';
import ab2 from '../images/ab2.png';
import ab3 from '../images/ab3.png';
import { useState } from "react";
import Modal from './Modal';

function Additionalbooth(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }

   const[popup,setpopup]= useState(false);


return(<div>
    {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MAv5iOP1f5k'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Additional Booth </h2>
 <h4 className='stepcontent'>Learn about Additional Booth</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Content</div> 
  <div className='contentcategories' onClick={Features}>Additional Booth</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Additional Booth</h3>
<p className='lines' >

A subscriber can purchase additional set of booths based on his subscription type.
Each set he purchases , he will be charged.The charges are already mentioned in the pricing page.<pre></pre>

The page lists out the additional details <br></br>
- The subscriber<br></br>
- Number of booths purchased<br></br>
- Date <br></br>


The subscriber first selects and event:



</p>

<div ><img src={addatt} height="120%" width='100%' ></img></div>

<p className='lines'><pre></pre>
In the add-ons page the subscriber selects an event and chooses the number of booths required as:


</p>
<div ><img src={ab2} height="120%" width='100%' ></img></div>
<p className='lines'><pre></pre>
On every order confirmed after payment ,the list appears in here.


</p>
<div ><img src={ab3} height="120%" width='100%' ></img></div>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

</div>
<br></br>
</div>



   </div>

</div>
<br></br><br></br><br></br>
</div>)



}

export default Additionalbooth;