import './content.css'
import Contentheader from './Contentheader';
import user from '../images/settings/user.png';
import newuser1 from '../images/settings/newuser1.png';
import { useState } from "react";
import Modal from './Modal';



function User(){

   const Features=()=>{
      const element = document.getElementById("users");
      element.scrollIntoView();
   }



   const[popup,setpopup]= useState(false);



return(<div>
   {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
   
    <h2 id='headingcontent'>User Management </h2>
 <h4 className='stepcontent'>Learn how to manage Operators and Roles</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>User Management</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >User Management</h3>
<p className='lines'  id='users'>
User management section allows you to manage operators and roles defined by the subscriber for the event . Subscribers can add new operators or edit existing operators details.


</p>

<li className='lines_' id='Customize'>The two major roles he can add are :</li>

<li className='lines'>Booth Operator</li>
<li className='lines'> Event Operator </li>



<pre></pre>

<li className='lines_' id='Customize'>Steps to add a user :</li>

<li className='lines'>Click on  'Add New User'.</li>
<li className='lines'>Choose a Role of the operator </li>
<li className='lines'>Enter the Name  </li>
<li className='lines'>Enter Mobile  No</li>
<li className='lines'>Enter the Email Id</li>
<li className='lines'>Input a Password </li>


<p className='lines' >

The user management panel then lists out all operators created by the subscriber.
‘Edit’  a user UI is same as add a user however with the difference of making changes or  updating existing user records .
Subscriber can even delete an operator with the delete button under the ‘action’ section of te panel.



<div ><img src={user} height="120%" width='100%' ></img></div>

</p>
Subscribers can add more operators under his role.In order to add a new user click on the new user button . Once created , the details can be edited or even deleted. 
<div ><img src={newuser1} height="120%" width='100%' ></img></div>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

</div>
</div>



   </div>

</div>
</div>)



}

export default User;
