import './content.css'
import Contentheader from './Contentheader';
import landing from '../images/eventsite/es.png';
import landing1 from '../images/eventsite/es1.png';
import landing2 from '../images/eventsite/es2.png';
import landing3 from '../images/eventsite/es3.png';
import landing4 from '../images/eventsite/es4.png';
import landing5 from '../images/eventsite/es5.png';
import landing6 from '../images/eventsite/es6.png';
import { useState } from "react";
import Modal from './Modal';


function Landing(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Preview=()=>{
    const element = document.getElementById("landing1");
    element.scrollIntoView();
 }
 const Editlink=()=>{
    const element = document.getElementById("landing2");
    element.scrollIntoView();
 }

 const Disclaimer=()=>{
    const element = document.getElementById("landing4");
    element.scrollIntoView();
 }

 const[popup,setpopup]= useState(false);




return(<div>{popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
   
    <h2 id='headingcontent'>Landing Page Management </h2>
 <h4 className='stepcontent'>Learn how to customize and view your Events</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div>
  <div className='contentcategories' onClick={Features}>Landing Page Features</div> 
  <div className='contentcategories' onClick={Preview} >Template Preview</div>
  <div  onClick={Editlink} className='contentcategories'>Edit Link</div>
  <div  onClick={Disclaimer} className='contentcategories'>Add Disclaimer to your Landing Page</div>
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Landing Page Management</h3>

<li className='lines_' >

This is the landing page of the event page through which attendees will enter the event.Attendees login to view the entire virtual event .

</li>

<li className='lines_' id='register'>

An attendee will be first taken to the landing page of any event.In this page,the organizer will be able to customize the elements to be displayed on the landing page.

</li>
<img src={landing}></img><pre></pre>
<li className='lines_' id='Featuress'>
Features that can be configured are: 
</li>
<li className='lines'>View and download the template associated with this page </li>





<li className='lines'>Disclaimer section </li>
<li className='lines'> 	External links</li>
<li className='lines'>Social media </li>
<li className='lines'> Instruction set
 </li>


<li className='lines'>Edit the layout and settings of the page </li><pre></pre>

<p className='lines'>Landing section can be configured in the following steps :
</p>

<div id='landing1' className=' '><img src={landing1 } width='100%'></img></div><br></br>
<p className='lines'>Firstly choose an event from the given dropdown. </p>
<div id='landing3' className='pt-3 '><img src={landing3} height="400px" width='100%'></img></div><br></br>

<div id='landing4' className=' pt-3'><img src={landing4} height="400px" width='100%'></img></div><br></br>
<p className='lines'></p>
<div id='landing5' ><img src={landing5} height="400px" width='100%'></img></div>

<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

</div>
</div>



   </div>

</div>
</div>)



}

export default Landing;