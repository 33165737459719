import './content.css'
import Contentheader from './Contentheader';
import { useState } from "react";
import Modal from './Modal';
import price1 from '../images/price1.png'
import price2 from '../images/price2.png'
import price3 from '../images/price3.png'
import price4 from '../images/price4.png'
import price5 from '../images/price5.png'
import price6 from '../images/price6.png'

function Pricingcontent(){
   const[popup,setpopup]= useState(false);
   const Features=()=>{
      const element = document.getElementById("users");
      element.scrollIntoView();
   }







return(<div  className='mb-5 pb-5'>
   {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MiITmJA8yd0'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Pricing </h2>


</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Pricing</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-4'>
<h3 >Pricing</h3>
<p className='lines mb-5 '  id='users'>
Pricing page of subscription module is for  subscribers to make easy decisions on product purchases.Best pricing page aims to provide a clear picture for customers to see the
pricing for their desired products.




</p>


<div><img src={price1} height="100%" width='100%'></img></div>
<div><img src={price2} height="100%" width='100%'></img></div>
<div><img src={price3} height="100%" width='100%'></img></div>
<div><img src={price4} height="100%" width='100%'></img></div>
<div><img src={price5} height="100%" width='100%'></img></div>
<div><img src={price6} height="100%" width='100%'></img></div>


<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>


</div>
</div>



   </div>

</div>
</div>)



}

export default Pricingcontent;