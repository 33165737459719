import './footer.css'
import fb from './images/fb.png';
import insta from './images/insta.png';
import lin from './images/in.png';
import yt from './images/yt.png';
import tw from './images/tw.png';





function Footer(){

return( 
    
    <div className="social  "  >
     <div><p className='powered text-center'>Powered by BizConnect</p> </div>
    
</div>
   
   




);

}

export default Footer