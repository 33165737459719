import './content.css'
import Contentheader from './Contentheader';
import dash from '../images/webinar/dashboard.png'
import { useState } from "react";
import Modal from './Modal';

function Dashboard(){

   const keyinfohandler=()=>{
      const element = document.getElementById("keyinfocontent");
      element.scrollIntoView();
   }

const chartshandler=()=>{
   const element = document.getElementById("chartscontent");
   element.scrollIntoView();
}
const[popup,setpopup]= useState(false);


return(<div>
      {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>
<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
   
    <h2 id='headingcontent'>Getting Started with Dashboard </h2>
 <h4 className='stepcontent '>Learn how to manage and operate Dashboard</h4>

</div>


<pre></pre>
  </div>

</div>

<pre></pre><pre></pre><pre></pre>
<div className='  container-sm'>
   <div className='row'>
<div className='col '>
   <div className=' categoriescont p-3 '>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories'>Dashboard</div> 
  <div className='contentcategories' onClick={keyinfohandler} >Key information</div>
  <div  onClick={chartshandler} className='contentcategories'>Charts</div>
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3>Dashboard</h3>
<p className='lines'>
Dashboard is often described as the control panel of a module. Subscribers login to the dashboard and can view the information and summary related to the progress and performance of their events. The dashboard is an updated information briefing on the status of your events 

</p>
<p  className='lines' id='keyinfocontent'>
The following key information of their events can be monitored from the dashboard:

</p>
<li className='lines'>Type of subscription and Expiry date.Subscribers could be :
Webinar Free<br></br>
Webinar Single<br></br>
Webinar monthly<br></br></li>

<li className='lines'>Total number of Attendees Remaining</li>
<li className='lines'>Count of the Upcoming Events</li>
<li className='lines'>Number of Running Events</li>
<li className='lines'>Total Number of Finished Events</li>
<li className='lines'> Count of his Entire Events</li><pre></pre><pre></pre>
<div><img src={dash} height="120%" width='100%' ></img></div>
<pre></pre><pre></pre>
<p className='lines' id='chartscontent' >
    
The chart given on the dashboard gives us a more coherent picture of the count of monthly events ,where.



</p>
<li className='lines'>X axis defines all the months </li>
<li className='lines'>Y Axis gives number of events</li>
<br></br>
The webinar dashboard thus summarizes the overall event proceedings.
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>
</div>
</div>



   </div>

</div>
</div>)



}

export default Dashboard;