import './content.css'
import Contentheader from './Contentheader';
import a1 from '../images/new_audi/a1.png';
import a2 from '../images/new_audi/a2.png';
import a3 from '../images/new_audi/a3.png';
import a4 from '../images/new_audi/a4.png';
import a5 from '../images/new_audi/a5.png';
import { useState } from "react";
import Modal from './Modal';



function Subscription(){

   const Login=()=>{
      const element = document.getElementById("login");
      element.scrollIntoView();
   }
   const Forget=()=>{
    const element = document.getElementById("forget");
    element.scrollIntoView();
 }
 const Register=()=>{
    const element = document.getElementById("register");
    element.scrollIntoView();
 }

 const Howto=()=>{
    const element = document.getElementById("howto");
    element.scrollIntoView();
 }


 const[popup,setpopup]= useState(false);



return(<div> {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=5JuPqgFSIb0'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Auditorium Module </h2>
 <h4 className='stepcontent'>Learn about Auditorium Management </h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Login}>Introduction</div> 
  <div className='contentcategories' onClick={Forget}>Configure</div>
  <div className='contentcategories' onClick={Register}>Customise</div>
  <div className='contentcategories' onClick={Howto}>Edit</div>

 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Introduction</h3>

<li className='lines'>Auditorium management module has all the features required for virtual
 auditorium of your event. It includes video clips, to quizzes,polls and presentations;.The system keeps track of auditorium functionalities. The system keeps records of auditorium  related details and helps add more features to it. The administrator can easily check the auditorium bookings and timings in the system gui. The system also allows notifies when a new event timing draws near.
</li>
<pre></pre>

<div id='Update' className='pb-5 '><img src={a1} height="100%" width='100%'></img></div>
<p className='lines' id='Featuress'>
Auditoriums can be configured after a subscriber selects the event.

<img src={a1} height="200px" width='50%'></img>
</p>

<p className='lines' id='forget'>
<span style={{ fontWeight: '600' }}>Customise the 
Auditorium</span><pre></pre>

</p><pre></pre>

<div id='Update' className='pb-5 '><img src={a3} height="100%" width='100%'></img></div>
<pre></pre>


<p className='lines'>
<span style={{ fontWeight: '600' }} id='register'>Configure Section:</span><pre></pre>
<img src={a4} height="100%" width='100%'></img> 
<ul>
    <li>Download Template
<ul><li>Background layout of event template.
Download to PC to work offline.
Upload upto 2MB file.
Reference view
Preformatted event template ready for display.
Easy reference to set the frontend layout.
Only viewable and not customizable
Event guide
A guidebook to host your event
Enable/Disable Event Guide
Accept instruction inputs and format as desired.
Banner
Setup classy banners and get started
Enable/Disable banners
Customize banner name,thumbnail and position.
Chat	
Chat display using javascript code .
Select meet, zoom or 8*8 chat.
Link of video chat can be given optionally.
Edit
Customize and start creating stunning lobbies.
Provide lobby name if required.
Upload background image and TV for lobby
</li></ul>


    </li>
</ul>

</p><pre></pre>

<img src={a4} height="100%" width='100%'></img> 
<img src={a5} height="200px" width='50%'></img>
<div id='Update' className='pb-5 '><img src={a4} height="100%" width='100%'></img> </div>


<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>






</div>
</div>



   </div>

</div>
</div>)



}

export default Subscription;