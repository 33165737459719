import logo from './images/logo.png';
import './header.css';
import { Link } from "react-router-dom";
import React from 'react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

/*<div className='home' ><Link  className='home '  to='/home'> Home</Link></div>*/
function Calheader(){


return(<div>
 <div class="navbar  ">
    <div className='topleft'>
   <div className='ms-3'><img   src={logo} height="100%" width="70%"></img>   </div>

   <div  ><Tippy className='tippy' content=' Go To Home'><Link to='/home'  className='home '  ><i class="bi bi-house-fill homebtn"></i> </Link></Tippy></div>
    </div>
    
  

    <div className=' '>
    <Link to='/pricing'  className="topbuttons  " ><i class="bi topicons bi-calculator-fill"></i> Pricing</Link>
 
    <a href='https://subscription.bizconnect.events/signup'  id='subscribe' className=" topbuttons  " >Subscribe</a>
  
    </div>
    
  </div>



 
  
  

</div>)

}
export default Calheader