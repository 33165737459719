import logo from '../images/logo.png';
import './contentheader.css';
import { Link } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


function Contentheader(){


return(<div className=' '>

<div class="navbar bbc ">
    <div className='topleft'>
   <div className='ms-3'><img   src={logo} height="100%" width="70%"></img>   </div>

   <div  ><Tippy className='tippy' content=' Go To Home'><Link to='/home'  className='home '  >
     <i class="bi bi-house-fill homebtn"></i> </Link></Tippy></div>
    </div>
    
  {/* <input className='fontAwesomecontent searchit' placeholder='Search Your Queries'></input> */}

    <div className=' '>
    <Link to="/request" className="topbuttons submit_request " ><i class="bi topicons bi-pencil-square"></i> Submit a Request</Link>
 
    <a href='https://subscription.bizconnect.events/signup'  id='subscribe' className=" topbuttons  " >Subscribe</a>
  
    </div>
    
  </div>

 
 


    </div>
 );

}
export default Contentheader;
