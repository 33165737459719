
import React,{useState,useEffect} from 'react';
import './Modal.css'
import ReactPlayer from 'react-player'
function Modal({setpopup,myurl}) {



 

 
   
    return (
      <div onClick={()=>{setpopup(false)}} className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setpopup(false);
            }}
            className="CloseBtn"
          >
            X
          </button>
        </div>
        <hr></hr>
        <div className="videocont">
          <ReactPlayer
            id="video"
            url={myurl}
            playing="true"
            controls="true"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default Modal;
