//header
import logo from "./images/logo.png";
import "./header.css";
import { Link } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function Header({title}) {
  return (
    <div className=" ">
      <div class="navbar  ">
        <div className="topleft">
          <div className="ms-3">
            <img src={logo} height="100%" width="70%"></img>{" "}
          </div>
        </div>

        <div className=" ">
          <Link to="/request" className="topbuttons submit_request ">
            <i class="bi topicons bi-pencil-square"></i> Submit a Request
          </Link>
          <Link to="/pricing" className="topbuttons ">
            <i class="bi topicons bi-cash-coin"></i> Pricing
          </Link>
          <Link to="/calculator" className="topbuttons  ">
            <i class="bi topicons bi-calculator-fill"></i> Calculator
          </Link>

          <a
            href="https://subscription.bizconnect.events/signup"
            id="subscribe"
            className=" topbuttons  "
          >
            Subscribe
          </a>
        </div>
      </div>

      <div className="row main ">
        <div className="col-3"></div>
        <div className="col-md-6">
          <div className="searchbox">
            <pre></pre>
            <h1 id="heading">BizConnect {title}</h1>
            {/* <h2 id='help'>How can we help you ?</h2>

   <input class="fontAwesome mt-2 searchit" placeholder='   Search Your Queries'>

</input> */}
          </div>

          {/* <div className='faqi mt-2 ' ><Link to='/FAQs' className='faqi'>FAQ</Link></div> */}
        </div>
        <pre></pre>
      </div>
    </div>
  );
}
export default Header;
