// webcomment
import "./App.css";

import Request from "./components/request";
import Calculator from "./components/calculator";
// import Header from "./components/header";
// import Content from "./components/maincontent";
import Footer from "./components/footer";
import Medium from "./components/calculator_medium";
import Small from "./components/calculator_small";
import Custom from "./components/calculator_custom";
import ScrollToTop from "./components/scroll";
import Pricing from "./components/pricing/pricing";
import Event from "./components/content/Event";
import CEvent from "./components/content/CEvent";
import Network from "./components/content/Network";
import Calc from "./components/content/Calculator";
import Auditorium from "./components/content/Auditorium";
import Pricingcontent from "./components/content/Pricing";
import Subscriptions from "./components/content/Subscriptions";
import SpeakerRegistration from "./components/content/SpeakerRegistration";
import SponsorRegistration from "./components/content/SponsorRegistration";
import OrganizerRegistration from "./components/content/OrganizerRegistration";
import ExhibitorRegistration from "./components/content/ExhibitorRegistration";
// import OthersRegistration from "./components/content/OthersRegistration";
import EventOperator from "./components/content/EventOperator";

import WebEvent from "./components/content/webEvent";
import WebCEvent from "./components/content/webCEvent";

import WebAttendee from "./components/content/webEventRegistration";

import WebTemplate from "./components/content/webTemplate";
import WebLanding from "./components/content/webLanding";
import Webinar from "./components/content/webinar";
import WebAnnouncement from "./components/content/webAnnouncement";
import WebOrders from "./components/content/webOrders";
import WebReports from "./components/content/webReports";
import WebMail from "./components/content/webMail";
import WebSubscriptions from "./components/content/webSubscriptions";
import WebSpeakerRegistration from "./components/content/webSpeakerRegistration";
import WebSponsorRegistration from "./components/content/webSponsorRegistration";
import WebOrganizerRegistration from "./components/content/webOrganizerRegistration";
import WebExhibitorRegistration from "./components/content/webExhibitorRegistration";
import WebOthersRegistration from "./components/content/webOthersRegistration";
import Webdashboard from "./components/content/webdashboard";
import WebEventRegistration from "./components/content/webEventRegistration";

import WebAdditionalAttendee from "./components/content/webAdditionalAttendee";
import WebAnalytics from "./components/content/webAnalytics";

import { Routes, Route } from "react-router-dom";
import Homepage from "./homepage";
import Dashboard from "./components/content/Dashboard";
import Exhibition from "./components/content/Exhibition";
import Landing from "./components/content/Landing";
import Speaker from "./components/content/Speaker";
import Lobby from "./components/content/Lobby";
import Booth from "./components/content/Booth";
import User from "./components/content/User";
import Template from "./components/content/Template";
import Subscription from "./components/content/Subscription";
import Registration from "./components/content/Registration";
// import SpeakerRegistration from './components/content/SpeakerRegistration';//todo: not founded
// import ExhibitorRegistration from './components/content/ExhibitorRegistration';//todo: not founded
// import OrganizerRegistration from './components/content/OrganizerRegistration';//todo: not founded
import Faq from "./components/faq";
import Announcement from "./components/content/Announcement";
import Leaderboard from "./components/content/Leaderboard";
import Reports from "./components/content/Reports";
import Orders from "./components/content/Orders";
import Mail from "./components/content/Mail";
import Additional from "./components/content/Additional";
import Additionalbooth from "./components/content/Additionalbooth";
import Additionalhalls from "./components/content/Additionalhalls";
import Navbar from "./components/content/Navbar";

function App() {
  return (
    <div className="App">
      <ScrollToTop>
        <Routes>
          <Route path="/request" element={<Request />} />

          <Route
            path="/home"
            element={
              <Homepage
                topic={"virtual/hybrid event"}
                title={"Knowledgebase"}
              />
            }
          />
          <Route
            path="/webinar"
            element={<Homepage topic={"Webinar"} title={"Webinar"} />}
          />
          <Route
            path="/networking"
            element={<Homepage topic={"Networking"} title={"Networking"} />}
          />

          <Route
            path="/"
            element={
              <Homepage
                topic={"virtual/hybrid event"}
                title={"Knowledgebase"}
              />
            }
          />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/calculator/small" element={<Small />} />
          <Route path="/calculator/medium" element={<Medium />} />
          <Route path="/calculator/custom" element={<Custom />} />
          <Route path="/pricing/" element={<Pricing />} />
          <Route path="/pricing/:pageNo" element={<Pricing />} />
          {/* <Route path="/pricing/webinar"  element={<WebinarPricing/>}  />
     <Route path="/pricing/virtual-hybridEvent"  element={<NewVirtualEventHibridEvent/>}  /> */}
          <Route path="/help/CEvent" element={<CEvent />} />
          <Route path="/help/dashboard" element={<Dashboard />} />
          <Route path="/help/EventRegistration" element={<Event />} />
          <Route
            path="/help/ExhibitionhallManagement"
            element={<Exhibition />}
          />
          <Route path="/help/LandingPageManagement" element={<Landing />} />
          <Route path="/help/NetworkManagement" element={<Network />} />
          <Route path="/help/SpeakerRoomManagement" element={<Speaker />} />
          <Route path="/help/BoothManagement" element={<Booth />} />
          <Route path="/help/LobbyManagement" element={<Lobby />} />
          <Route path="/help/UserManagement" element={<User />} />
          <Route path="/help/TemplateManagement" element={<Template />} />
          <Route path="/help/EstimateEventCost" element={<Calc />} />
          <Route path="/help/Pricing" element={<Pricingcontent />} />
          <Route path="/help/Subscription" element={<Subscription />} />
          <Route path="/help/Registration" element={<Registration />} />
          <Route path="/help/Announcement" element={<Announcement />} />
          <Route path="/help/Leaderboard" element={<Leaderboard />} />
          <Route path="/help/Reports" element={<Reports />} />
          <Route path="/help/Orders" element={<Orders />} />
          <Route path="/help/MailConfiguration" element={<Mail />} />
          <Route path="/help/Additionalattendee" element={<Additional />} />
          <Route path="/help/Additionalbooths" element={<Additionalbooth />} />
          <Route
            path="/help/AdditionalExhibition"
            element={<Additionalhalls />}
          />
          <Route path="/help/Navbar" element={<Navbar />} />
          <Route path="/help/Subscriptions" element={<Subscriptions />} />
          <Route path="/help/EventOperator" element={<EventOperator />} />

          {
            <Route
              path="/help/SpeakerRegistration"
              element={<SpeakerRegistration />}
            />
          }
          {
            <Route
              path="/help/SponsorRegistration"
              element={<SponsorRegistration />}
            />
          }
          {
            <Route
              path="/help/OrganizerRegistration"
              element={<OrganizerRegistration />}
            />
          }
          {
            <Route
              path="/help/ExhibitorRegistration"
              element={<ExhibitorRegistration />}
            />
          }
          <Route
            path="/help/otherRegistration"
            element={<otherRegistration />}
          />

          <Route path="/help/Auditorium" element={<Auditorium />} />
          <Route path="/FAQs" element={<Faq />} />
          <Route path="/help/webdashboard" element={<Webdashboard />} />
          <Route path="/help/webEvent" element={<WebEvent />} />
          <Route path="/help/WebCEvent" element={<WebCEvent />} />
          <Route path="/help/webAttendee" element={<WebAttendee />} />

          <Route path="/help/webTemplate" element={<WebTemplate />} />
          <Route path="/help/webLanding" element={<WebLanding />} />
          <Route path="/help/webinar" element={<Webinar />} />
          <Route path="/help/webAnnouncement" element={<WebAnnouncement />} />
          <Route path="/help/webOrders" element={<WebOrders />} />
          <Route path="/help/webReports" element={<WebReports />} />
          <Route path="/help/webMail" element={<WebMail />} />
          <Route path="/help/webSubscriptions" element={<WebSubscriptions />} />
          <Route path="/help/webAttendee" element={<WebAttendee />} />
          <Route
            path="/help/webAdditionalAttendee"
            element={<WebAdditionalAttendee />}
          />
          <Route path="/help/webAnalytics" element={<WebAnalytics />} />

          <Route
            path="/help/webEventRegistration"
            element={<WebEventRegistration />}
          />
          <Route
            path="/help/webSpeakerRegistration"
            element={<WebSpeakerRegistration />}
          />
          <Route
            path="/help/webSponsorRegistration"
            element={<WebSponsorRegistration />}
          />
          <Route
            path="/help/webOrganizerRegistration"
            element={<WebOrganizerRegistration />}
          />
          <Route
            path="/help/webExhibitorRegistration"
            element={<WebExhibitorRegistration />}
          />
          <Route
            path="/help/webOthersRegistration"
            element={<WebOthersRegistration />}
          />
          <Route path="/module/:apiId" element={<Dashboard />} />
        </Routes>
      </ScrollToTop>
      <pre></pre>

      <Footer />
    </div>
  );
}

export default App;
