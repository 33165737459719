import './content.css'
import Contentheader from './Contentheader';
import subscription1 from '../images/weborders/subscriptions.png';

import { useState } from "react";
import Modal from './Modal';

import landing5 from '../images/landing5.png';
import speaker1 from '../images/speaker1.png';


function Subscription(){

   const Login=()=>{
      const element = document.getElementById("login");
      element.scrollIntoView();
   }
   const Forget=()=>{
    const element = document.getElementById("forget");
    element.scrollIntoView();
 }
 const Register=()=>{
    const element = document.getElementById("register");
    element.scrollIntoView();
 }

 const Howto=()=>{
    const element = document.getElementById("howto");
    element.scrollIntoView();
 }


 const[popup,setpopup]= useState(false);



return(<div> {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=5JuPqgFSIb0'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Subscription Module </h2>
 <h4 className='stepcontent'>Learn about Login Signup </h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Login}>Login</div> 
  <div className='contentcategories' onClick={Forget}>Forget Password</div>
  <div className='contentcategories' onClick={Register}>Register</div>
  <div className='contentcategories' onClick={Howto}>How to Register?</div>

 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Login Module</h3>

<li className='lines' id='login'>The Subscriptions module lists out all the subscriptions the subscriber has opted for.</li>
<li className='lines'>It displays the result of the transaction details after the payment gateway procedure for change of plans.
</li>
<li className='lines'></li>
<pre></pre>

<div id='Update' className='pb-5 '><img src={subscription1} height="100%" width='100%'></img></div>
<p className='lines' id='Featuress'>
The transactions are either all or none procedure.</p>


<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>






</div>
</div>



   </div>

</div>
</div>)



}

export default Subscription;