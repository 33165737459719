import './content.css'
import Contentheader from './Contentheader';
import subscription1 from '../images/subscription.png';
import subscription2 from '../images/subscription2.png';
import subscription3 from '../images/subscription3.png';
import subscription4 from '../images/subscription4.png';
import subscription5 from '../images/subscription5.png';
import { useState } from "react";
import Modal from './Modal';

import landing5 from '../images/landing5.png';
import speaker1 from '../images/speaker1.png';


function Subscription(){

   const Login=()=>{
      const element = document.getElementById("login");
      element.scrollIntoView();
   }
   const Forget=()=>{
    const element = document.getElementById("forget");
    element.scrollIntoView();
 }
 const Register=()=>{
    const element = document.getElementById("register");
    element.scrollIntoView();
 }

 const Howto=()=>{
    const element = document.getElementById("howto");
    element.scrollIntoView();
 }


 const[popup,setpopup]= useState(false);



return(<div> {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=5JuPqgFSIb0'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Subscription Module </h2>
 <h4 className='stepcontent'>Learn about Login Signup </h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Login}>Login</div> 
  <div className='contentcategories' onClick={Forget}>Forget Password</div>
  <div className='contentcategories' onClick={Register}>Register</div>
  <div className='contentcategories' onClick={Howto}>How to Register?</div>

 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Login Module</h3>

<li className='lines' id='login'>The Login Module represents the main unit of authentication.</li>
<li className='lines'>It helps a user access applications with authorized credentials.
</li>
<li className='lines'>The users can enter their user ID (Email or username) and password to get 
an entry into the system.</li>
<pre></pre>

<div id='Update' className='pb-5 '><img src={subscription1} height="100%" width='100%'></img></div>
<p className='lines' id='Featuress'>
If credentials do not match the user will get an error message and will have to re-enter the credentials properly.


Users can also use the Social media authenticators  to login to the module :


    
The system allows Google and  Linkedin credentials for logging into the system.
</p>

<p className='lines' id='forget'>
<span style={{ fontWeight: '600' }}>Forgot your password:</span><pre></pre>

This is an important feature for anybody who has forgotten their password and wants to reset it. The user will be prompted for email to mail a password reset link that allows user to choose a new one.

Click the  <span style={{ fontWeight: '600' }}>“Forgot Password”</span> link.  And in the email field , enter the email ID to which 
the password to be reset should be sent   .

</p><pre></pre>

<div id='Update' className='pb-5 '><img src={subscription2} height="100%" width='100%'></img></div>
<pre></pre>


<p className='lines'>
<span style={{ fontWeight: '600' }} id='register'>Registration Section:</span><pre></pre>
The registration section in subscription helps create and design the perfect user registration forms.

Registration can be done either using the 'Register' link or even using socialite authenticators
like 'Google' and 'linkedIn'.


</p><pre></pre>

<div id='Update' className='pb-5 '><img src={subscription3} height="200px" width='40%'></img> <img src={subscription4} height="200px" width='50%'></img></div>


<p className='lines'>
<span style={{ fontWeight: '600' }} id='howto'>How to  Register:</span><pre></pre>
To create a new user click 'Register' link in Login Page<br></br>
 1. Register link will take you to signup page.<br></br>
 2. Provide the appropriate name and designation.<br></br>
 3. Enter Email and the Password.<br></br>
 4. Enter confirm password field with a matching password.<br></br>
  5. Click on 'Signup' button to save the details.<br></br>
Note: Error messages show up on validation fails. Eg:  if passwords do no match, Fields are empty  etc.


</p><pre></pre>

<div id='Update' className='pb-5 '><img src={subscription5} height="100%" width='100%'></img> </div>


<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>






</div>
</div>



   </div>

</div>
</div>)



}

export default Subscription;