import './content.css'
import Contentheader from './Contentheader';
import { useState } from "react";
import Modal from './Modal';


function Leaderboard(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }
   const Preview=()=>{
    const element = document.getElementById("landing1");
    element.scrollIntoView();
 }
 const Editlink=()=>{
    const element = document.getElementById("landing2");
    element.scrollIntoView();
 }

 const Disclaimer=()=>{
    const element = document.getElementById("landing4");
    element.scrollIntoView();
 }
 const[popup,setpopup]= useState(false);





return(<div> {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Leaderboard </h2>
 <h4 className='stepcontent'>Learn how to manage Leaderboard</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Leaderboard</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Leaderboard</h3>
<p className='lines' >

Virtual attendees can earn badges in the Leaderboard Challenge as they participate in conference activities and network with other attendees. The top participants are eligible for drawing prizes and earning rewards.

<pre></pre>

Through leaderboard subscribers can also configure the following features :
Set a schedule for displaying the scorers from a  particular start date to end date.
Set the rules , points and prizes applicable for the scoreboard .<pre></pre>
Leaderboard module also allows  the following attributes to be customized: 
<li className='lines'>Title to display on leaderboard</li>
<li className='lines'>Color scheme for the text</li>
<li className='lines'>Change the different backgrounds,headers</li>
<li className='lines'>Enable the desired fields to show up on the leaderboard</li>

 





</p>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>

<br></br>

</div>
</div>



   </div>

</div><br></br><br></br>
</div>)



}

export default Leaderboard;