import './content.css'
import Contentheader from './Contentheader';
import t1 from '../images/webinar/new_template/t1.png';



import { useState } from "react";
import Modal from './Modal';

function Template(){

   const Features=()=>{
      const element = document.getElementById("temp");
      element.scrollIntoView();
   }
   const Configure=()=>{
    const element = document.getElementById("configure");
    element.scrollIntoView();
 }


 const[popup,setpopup]= useState(false);




return(<div>{popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Template Management </h2>
 <h4 className='stepcontent'>Learn how to get Templates for your Event</h4>

</div>

<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-4 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Template Management</div> 
  <div className='contentcategories' onClick={Features}>Steps to configure</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Template Management</h3>
<p className='lines'  id='temp'>Template helps you create a comprehensive overview of an event you are planning to run.Templates are tne ideal visual analyser of how an event layout should look.
Any subscriber can select variety of templates for the different modules of an event. Starting from the landing page till the very last page of the event the subscriber is provided with different templates to choose from.

</p>

<ul>
    <li className='temp' id='Customize'>Steps to configure the templates:                

<ul className='temp'>
<li className='temp pb-1' id='configure'>1. Select ‘Landing Page’  template from the list .</li>
</ul>
<ul className='temp pb-1'>
    <li>a. View preview.
     <br></br> b.Confirm
    </li>
</ul>

<div ><img src={t1} height="120%" width='100%' ></img></div>



<li className='temp'>7. After selection of all the templates  click ‘confirm’ button
</li>
</li>
</ul>



<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>






</div>
</div>



   </div>

</div>
</div>)



}

export default Template;