import Header from "./components/header";
import Content from "./components/maincontent";

function Homepage({topic , title}) {
  return (
    <div>
      <Header title={title} />
      <div className="pb-4"></div>
      <Content topic={topic} />
    </div>
  );
}
export default Homepage;
