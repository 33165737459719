import './content.css'
import Contentheader from './Contentheader';
import mail1 from '../images/settings/mail.png';
import { useState } from "react";
import Modal from './Modal';


function Mail(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }

   const[popup,setpopup]= useState(false);


return(<div>
      {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=0ZOX2K-6CyI'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Mail Configuration </h2>
 <h4 className='stepcontent'>Learn how to  configure Mail</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Mail Configuration</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Mail Configuration</h3>
<p className='lines' >

Mails are the most important module for subscription .It is in this section where on any new  event trigger,mails are generated to attendees or roles of similar type .In certain cases, mails are generated to the subscribers themselves.



</p>

<div ><img src={mail1} height="120%" width='100%' ></img></div>

<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>



</div>
<br></br><br></br><br></br><br></br>
</div>



   </div>

</div>
<br></br><br></br><br></br>
</div>)



}

export default Mail;