import './content.css'
import Contentheader from './Contentheader';
import addatt from '../images/orders/attendees.png';
import { useState } from "react";
import Modal from './Modal';


function Additional(){

   const Features=()=>{
      const element = document.getElementById("Featuress");
      element.scrollIntoView();
   }

   const[popup,setpopup]= useState(false);


return(<div> {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MAv5iOP1f5k'} />}
<Contentheader/>
<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>
 
    <h2 id='headingcontent'>Additional Attendee </h2>
 <h4 className='stepcontent'>Learn about Additional Attendee</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Additional Attendee</div> 
 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h3 >Additional Attendee</h3>
<p className='lines' >

A subscriber can purchase additional set of attendees based on his subscription type.
Each set he purchases will be charged.The charges are already mentioned in the pricing page<pre></pre>

The page lists out the additional details <br></br>
- The subscriber<br></br>
- Number of attendees purchased<br></br>
- Date <br></br>
On every order confirmed after payment ,the list appears in here.




</p>

<div ><img src={addatt} height="120%" width='100%' ></img></div>

<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>



</div>
<br></br><br></br><br></br><br></br>
</div>



   </div>

</div>
<br></br><br></br><br></br>
</div>)



}

export default Additional;