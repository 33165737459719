import './content.css'
import Contentheader from './Contentheader';
import reg1 from '../images/reg1.png'
import reg2 from '../images/reg2.png'
import reg3 from '../images/reg3.png'
import reg4 from '../images/reg4.png'
import { useState } from "react";
import Modal from './Modal';

function Registration(){

   const Features=()=>{
      const element = document.getElementById("users");
      element.scrollIntoView();
   }

   const Form=()=>{
    const element = document.getElementById("form");
    element.scrollIntoView();
 }
 const Add=()=>{
    const element = document.getElementById("add");
    element.scrollIntoView();
 }
 const Import=()=>{
    const element = document.getElementById("import");
    element.scrollIntoView();
 }


 const[popup,setpopup]= useState(false);


return(<div>
   {popup && <Modal setpopup={setpopup} myurl={'https://www.youtube.com/watch?v=MiITmJA8yd0'} />}
<Contentheader/>

<div className='row main '>
<div className='col-3'></div>
  <div className='col-md-6'>
  <div className='searchbox'>

    <h2 id='headingcontent'>Registration Section</h2>
 <h4 className='stepcontent'>Learn how to Register</h4>

</div>


<pre></pre>
  </div>

</div>
<br></br><br></br>
<div className='  container-sm'>
   <div className='row'>
<div className='col-md-3 '>
   <div className=' categoriescont p-3 ' id='index'>
  <div className='categories'>Table of Contents</div> 
  <div className='contentcategories' onClick={Features}>Registration Module</div> 
  <div className='contentcategories' onClick={Form}>Create Form</div> 
  <div className='contentcategories' onClick={Add}>Add Categories/Labels</div> 
  <div className='contentcategories' onClick={Import}>Import Excel Sheet</div> 
  </div>
</div>
<div className='col-md-9 descripton'>
   <div className='p-3'>
<h6>Speakers have one of the most important roles in an event.They tend to set the pitch for the event.</h6>





<li className='lines' id='Customize'>When you click on the speakers, your screen will show the following. </li>
<br></br><div><img src={reg1} height="100%" width='100%'></img></div>
<pre></pre>
<li className='lines' id='Customize'>To initiate the registration process, click on the Create Form. The following screen will be displayed with different options.   </li>
<br></br><div id='form'><img src={reg2} height="100%" width='100%'></img></div><pre></pre>
<li className='lines' id='Customize'>Here subcribers can alter and add every one of the vital subtleties that would be expected for enrollment of the occasion. To add more categories/labels to the enlistment structure, click on the addition symbol on the upper right corner.
  </li><br></br>
  <li className='lines' id='Customize'>Subsequent to tapping on that, the accompanying screen will come.

  </li>
<br></br><div id='add'><img src={reg3} height="100%" width='100%'></img></div><pre></pre>


<li className='lines' id='Customize'>Here in input, you can choose the kind of mark you need to have. The short text is utilized for more modest texts, for example, first name, age and so on Long text is the point at which you have a satisfactory measure of text for example Name, address and so on The drop-down choice is for when you have various choices to browse like nation or area. Radio is utilized for a decision for example at the point when you need to pick orientation or identity and comparative. Through web-based media, individuals can interface their online media records, for example, LinkedIn, Instagram, Facebook and so on. The record menu is involved when there's a requirement for a participant to transfer a record or give a few reports.

  </li><br></br>
  <li className='lines' id='Customize'>After entering a label and verifying whether you want to make it mandatory or not, click Submit.


  </li><br></br>
  <li className='lines' id='Customize'>A dropbox will show that the label is added. In the event if there is a need to erase or alter the mark, one can constantly do as such by tapping the appropriate choice under the 'action' category..




</li><br></br>
<li className='lines' id='Customize'>After the enrollment is done, click on back and the accompanying screen will show up.
To avoid manually adding attendees one at a time, subscribers can click the import icon to make several entries. As soon as you click the the import button the following screen should appear.
</li>
<br></br><div id='import'><img src={reg4} height="100%" width='100%'></img></div><pre></pre>

<li className='lines' id='Customize'>Here when you click on import, an excel sheet would be downloaded in your pc. Top it off with the expected information and afterward continue to additional means.
</li>
<br></br>

<li className='lines' id='Customize'>Pick the excel document, and afterward click on import. In this manner all the entries would be done immediately.
The alternate method for enlisting for occasion is by tapping on, 'copy link icon'

</li>
<br></br>

<li className='lines' id='Customize'> By tapping on this, a connection would be consequently duplicated to your console which you will additionally use to advance the occasion and permit individuals to enlist themselves.
The connection will take you to a page with the accompanying design.

</li>
<br></br>

<li className='lines' id='Customize'> Here the participants can fill in their data by themselves. Furthermore assuming the supporter wants  to have a duplicate of the multitude of participants he/she can basically tap on 'export' to get that.


</li>
<br></br>
<li className='lines' id='Customize'> It should be noticed that similar advances are utilized for different enlistments like speakers, big cheeses, entertainers and others.



</li>
<br></br>
<br></br>
<div className='text-center'>
<button onClick={()=>{setpopup(true)}} className=' vbu '>Watch Video</button>

</div>
</div>
</div>



   </div>

</div>
</div>)



}

export default Registration;